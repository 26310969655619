<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item label="组织" v-if="$store.state.isAreaManager">
            <el-select v-model="listQuery.Search.StrOrganizationID" placeholder="请选择" @change="getSWList">
              <el-option
                  v-for="item in OrganizationList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="书屋">
            <el-select v-model="listQuery.Search.StrLibraryID" placeholder="请选择">
              <el-option
                  v-for="item in swList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="listQuery.Search.DTStartCreatedDate"
              type="date"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="listQuery.Search.DTEndCreatedDate"
              type="date"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              @change = "choose"

            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="工作人员" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrCreatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书编目(册)" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedBookCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书借还(册)" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedBookBorrowedCount }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="图书归还(册)" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.CostCount }}册</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="图书修改(册)" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.CostCount }}册</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="期刊编目(册)" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedJournalCount }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="期刊借阅(册)" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.CostCount }}册</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="期刊归还(册)" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.CostCount }}册</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="读者办卡(张)" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedReaderCount }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="total" style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name:'WorkloadStatistics',
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        Search:{}
      },
      libraryQuery: {
        Page: 1,
        Perpage: 999,
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        Search:{}
      },
      total: 0,
      swList: [],
      Url: ''
    }
  },
  created() {

    //刷新是否是区域管理员
    this.$store.commit('changeIsAreaManager');
    if (!this.$store.state.isAreaManager){ this.getSWList()}else { this.getorglist()}
    this.getList()
  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.listQuery.Search.DTStartCreatedDate)
          const day2 = new Date(this.listQuery.Search.DTEndCreatedDate)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.Search.DTEndCreatedDate = ''
          }

      },
    getList() {
        // eslint-disable-next-line no-unused-vars
        const parem ={
            Page: 1,
            Perpage: 10,
            Search:{}
        }
      var end = this.listQuery.Search.DTEndCreatedDate
      if (end!==undefined&&end!=null) {
          parem.Search.DTStartCreatedDate = this.listQuery.Search.DTStartCreatedDate + "T00:00:00.999Z"
          parem.Search.DTEndCreatedDate = this.listQuery.Search.DTEndCreatedDate + "T23:59:59.999Z"
      }
      this.listLoading=true
      this.$axios({
        url: this.localpath + 'organizations/managerworkcount',
        method: 'post',
        data: JSON.stringify(parem),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total=res.data.total
          this.listLoading = false
        }
      })
    },
    //重置
    reset(){
      this.listQuery={
        Page: 1,
        Perpage: 10,
        Search:{}
      }
      this.libraryQuery={
        Page: 1,
        Perpage: 999,
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        Search:{}
      },
      this.getList();
    },
    // 获取书屋
    getSWList() {
      if (!this.$store.state.isAreaManager){
        //普通管理员
        this.$axios({
          url: this.localpath + 'librarys',
          method: 'get',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.swList = res.data.msg
          }
        })
      }else{
        //区域管理员
        this.libraryQuery.Search.StrOrganizationID=this.listQuery.Search.StrOrganizationID
        this.$axios({
          url: this.localpath + 'librarys/search',
          method: 'post',
          data: JSON.stringify(this.libraryQuery),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.swList = res.data.msg
          }
        })
      }

    },
    //获取学校
    getorglist(){
      this.$axios({
        url: this.localpath + 'organizations/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.OrganizationList = res.data.msg
        }
      })
    },
    // 分页
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

