<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item label="组织" v-if="$store.state.isAreaManager">
            <el-select v-model="listQuery.StrOrganizationID" placeholder="请选择" @change="getSWList">
              <el-option
                  v-for="item in OrganizationList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="书屋">
            <el-select v-model="listQuery.StrLibraryID" placeholder="请选择">
              <el-option
                  v-for="item in swList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="查询分类方式">
            <el-select v-model="listQuery.IsCLC" placeholder="请选择">
              <el-option label="中图法" value="中图法"/>
              <el-option label="自定义分类" value="自定义分类"/>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="分类名">-->
<!--            <el-input v-model="listQuery.StrTypeName" placeholder="请输入分类" style="width: 200px"/>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-date-picker
                v-model="listQuery.DTStartTime"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.DTEndTime"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="图书分类" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrBookTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分类名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrBookTypeZH }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书种数" align="center">
        <template v-slot="{row}">
          <span>{{ row.TotalISBN }}种</span>
        </template>
      </el-table-column>
      <el-table-column label="图书册数" align="center">
        <template v-slot="{row}">
          <span>{{ row.TotalCount }}册</span>
        </template>
      </el-table-column>
      <el-table-column label="总金额" align="center">
        <template v-slot="{row}">
          <span>{{ row.CostCount }}元</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name:'ClassifiedStatistics',
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        IsCLC:"中图法"
      },
      libraryQuery: {
        Page: 1,
        Perpage: 999,
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        Search:{}
      },
      total: 0,
      swList: [],
      Url: ''
    }
  },
  created() {
    //刷新是否是区域管理员
    this.$store.commit('changeIsAreaManager');
    if (!this.$store.state.isAreaManager){ this.getSWList()}else { this.getorglist()}
    this.getList()
  },
  methods: {

      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.listQuery.DTStartTime)
          const day2 = new Date(this.listQuery.DTEndTime)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.DTEndTime = ''
          }
      },
    getList() {

      this.listLoading=true
      const search={
        IsCLC:this.listQuery.IsCLC=="中图法",
        StrLibraryID:this.listQuery.StrLibraryID,
        DTStartTime:this.listQuery.DTStartTime ,
        DTEndTime:this.listQuery.DTEndTime ,
        StrOrganizationID:this.listQuery.StrOrganizationID
      }
        var end = this.listQuery.DTEndTime
        if (end!==undefined&&end!=null) {
          search.DTStartTime = this.listQuery.DTStartTime + "T00:00:00.999Z"
          search.DTEndTime = this.listQuery.DTEndTime + "T23:59:59.999Z"
        }
      this.$axios({
        url: this.localpath + 'organizations/booktypescount',
        method: 'post',
        data: JSON.stringify(search),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          for(var i = 0 ; i<this.list.length;i++){
            var cost =this.list[i].CostCount
            let point = String(cost).length - (String(cost).indexOf('.') + 1)
            //带有小数且超过两位小数
            if(String(cost).indexOf('.')!==-1&&point>2)
              this.list[i].CostCount = cost.toFixed(2)
          }
          this.listLoading = false
        }else{
          this.listLoading = false
        }
      })
    },
    //重置
    reset(){
      this.listQuery={
        IsCLC:"中图法"
      }
      this.getList();
    },
    // 获取书屋
    getSWList() {
      if (!this.$store.state.isAreaManager){
        //普通管理员
        this.$axios({
          url: this.localpath + 'librarys',
          method: 'get',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.swList = res.data.msg
          }
        })
      }else{
        //区域管理员
        this.libraryQuery.Search.StrOrganizationID=this.listQuery.StrOrganizationID
        this.$axios({
          url: this.localpath + 'librarys/search',
          method: 'post',
          data: JSON.stringify(this.libraryQuery),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.swList = res.data.msg
          }
        })
      }

    },
    //获取学校
    getorglist(){
      this.$axios({
        url: this.localpath + 'organizations/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.OrganizationList = res.data.msg
        }
      })
    },
    // 分页
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

