<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-edit"
          size="mini"
          style="margin:0 0 10px 0;"
          @click="handleCreate"
      >添加</el-button>
    </div>

    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="类型名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书可借数量" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntNumOfBook }}</span>
        </template>
      </el-table-column>
      <el-table-column label="期刊可借数量" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntNumOfJournal }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开卡费用" align="center">
        <template v-slot="{row}">
          <span>{{ row.DCardOpenFee }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型描述" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrDescription }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
      >
        <template v-slot="{row}">
          <el-button type="success" size="mini" @click="findReader(row)">查看读者</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row,$index)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="35%" :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-position="left"
          label-width="130px"
          style="margin-left:50px;"
          size="small"
      >
        <el-row :gutter="20">
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="类型名称" prop="StrName">
              <el-input v-model="formModel.StrName" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="图书可借数量" prop="IntNumOfBook">
              <el-input v-model="formModel.IntNumOfBook" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="期刊可借数量" prop="IntNumOfJournal">
              <el-input v-model="formModel.IntNumOfJournal" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="开卡费用" prop="DCardOpenFee">
              <el-input v-model="formModel.DCardOpenFee" />
            </el-form-item>
          </el-col>
<!--          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">-->
<!--            单元必填<el-checkbox v-model="checked"></el-checkbox>-->
<!--            小组必填<el-checkbox v-model="checked"></el-checkbox>-->
<!--            部门必填<el-checkbox v-model="checked"></el-checkbox>-->
<!--            学段必填<el-checkbox v-model="checked"></el-checkbox>-->
<!--          </el-col>-->
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="类型描述">
              <el-input v-model="formModel.StrDescription"  type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'Type',
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        OrderBy: {"DTCreatedDate":-1},
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        StrName: [
          { required: true, message: '请输入类型分类', trigger: 'blur' }
        ],
        IntNumOfBook: [
          { required: true, message: '请输入图书可借数量', trigger: 'blur' }
        ],
        IntNumOfJournal: [
          { required: true, message: '请输入期刊可借数量', trigger: 'blur' }
        ],
        DCardOpenFee: [
          { required: true, message: '请输入开卡费用', trigger: 'blur' }
        ],
        IntSpecialNumOfBook: [
          { required: true, message: '请输入限制数量', trigger: 'blur' }
        ]
      },
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      formModel: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      this.$axios({
        url: this.localpath + 'readertypes/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })
    },
    findReader(row){
      localStorage.setItem("keyName","StrReaderTypeName")
      localStorage.setItem("keywords",row.StrName)
      this.$router.push("/readerfile")
    },
    //添加或者编辑
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.formModel.IntNumOfBook=parseInt(this.formModel.IntNumOfBook)
            this.formModel.IntNumOfJournal=parseInt(this.formModel.IntNumOfJournal)
            this.formModel.IntSpecialNumOfBook=parseInt(this.formModel.IntSpecialNumOfBook)
            this.$axios({
              url: this.localpath + 'readertypes',
              method: 'put',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
              }else{
                this.$message({
                  message:res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      }
      else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.formModel.IntNumOfBook=parseInt(this.formModel.IntNumOfBook)
            this.formModel.IntNumOfJournal=parseInt(this.formModel.IntNumOfJournal)
            this.formModel.IntSpecialNumOfBook=parseInt(this.formModel.IntSpecialNumOfBook)
            this.formModel.DCardOpenFee=this.formModel.DCardOpenFee.toString()
            this.$axios({
              url: this.localpath + 'readertypes/'+this.formModel.StrID,
              method: 'patch',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      }
    },
    handleCreate() {
      this.formModel = {}
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.formModel =row
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除该类型吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.localpath + 'readertypes/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }else{
            this.$notify({
              message:res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>

