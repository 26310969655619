<template>
  <div
      class="container"
      style="width:100%;"
  >
    <el-upload
        v-loading.fullscreen="uploadLading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.7)"
        class="upload-demo"
        drag
        :action="uploadUrl"
        :headers="picheaders"
        style="text-align:center;"
        name="BOOKFile"
        :on-success="handleSuccess"
        :on-progress="onProgress"
        :on-remove="handleRemove"
        :on-error="handleError"
        :file-list="fileList"
    >
      <i class="el-icon-upload" />

    </el-upload>
    <div class="el-upload__text" style="text-align: center">将文件拖到此处，或<em>点击上传</em></div>
    <div class="el-upload__tip" style="margin-top:30px;text-align: center">
      <a :href="Url " style="color:#6290ff">点击下载模板</a>
    </div>
  </div>
</template>

<script>
export default {
  name:'BookImport',
  data() {
    return {
      list: null,
      total: 0,
      uploadLading: false,
      listLoading: false,
      dialogFormVisible: false,
      listQuery: {
        pageIndex: 1,
        pageSize: 10
      },
      Url:this.localpath+'templates/Books.xlsx',
      picheaders:
          {
            'Authorization': localStorage.getItem("token")
          },
      uploadUrl: '',
      fileList: [],
    }
  },
  created() {
    this.uploadUrl =this.localpath+'books/import'
  },
  methods: {
    onProgress() {
      this.uploadLading = true
    },
    // 文件上传成功回调
    handleSuccess(res, file, filelist) {
      if (res.ok) {
        this.fileList = filelist
        this.uploadLading = false
        this.$confirm(res.msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '图书档案',
          type: 'success'
        }).then(() => {}).catch(() => {
          this.$router.push('/bookfile')
        })
        this.fileList = []
      }else{
        this.$message({
          type: 'error',
          message: res.msg
        })
        this.uploadLading = false
      }
    },
    handleError() {
      this.uploadLading = false
      this.$message({
        type: 'error',
        message: '上传失败'
      })
    },
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileList.splice(index, 1)
          this.$message({
            message: '移除成功',
            type: 'success'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-upload{
  width: 100%;
}
.el-upload-dragger{
  width: 100%;
  height: 200px;
}
.el-upload-dragger .el-icon-upload{
  font-size: 120px;
  line-height: 80px;
}
</style>
