<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small" :model="query">
          <el-form-item label="书屋">
            <el-select v-model="query.StrLibraryID" placeholder="请选择">
              <el-option
                  v-for="item in swList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="query.DTStartCreatedDate"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="query.DTEndCreatedDate"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"/>
          </el-form-item>
          <el-form-item>
            <el-radio v-model="query.strType" label="损毁">损毁</el-radio>
            <el-radio v-model="query.strType" label="丢失">丢失</el-radio>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getList">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="warning"
                size="mini"
                icon="el-icon-refresh"
                @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;overflow:auto;">
      <el-table-column
          label="图书条码"
          prop="id"
          align="center"
          width="150">
        <template v-slot="{row}">
          <span>{{ row.StrBookBarcode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="正题名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrPositiveTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属书屋" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrLibraryName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="读者卡号" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrReaderCardNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrReaderName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作时间" align="center" >
        <template v-slot="{row}">
          <span>{{ new Date(row.DTCreatedDate).toLocaleString() }}</span>
        </template>
      </el-table-column>
      <el-table-column label="丢失损毁" align="center" width="100">
        <template v-slot="{row}">
          <span>{{ row.StrFineReason=="Lost"?"丢失":"损毁" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作员" align="center" width="100">
        <template v-slot="{row}">
          <span>{{ row.StrCreatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="100"
      >
        <template v-slot="{row}">
          <el-button type="primary" size="mini" @click="btnDel(row)">撤消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:20px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="query.Page"
          :page-size="query.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name:'LostAndBreakList',
  data() {
    return {
      list: null,
      listLoading: true,
      rules: {
      },
      downloadLoading: false,
      total:0,
      query: {
        Page: 1,
        Perpage: 10,
        Sort:"-1",
        strType:"损毁",
        OrderBy: "DTCreatedDate",},
      swList: [],
      Url: ''
    }
  },
  created() {
    this.getList()
    this.getSWList()
    // this.Url = process.env.VUE_APP_BASE_API
  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.query.DTStartCreatedDate)
          const day2 = new Date(this.query.DTEndCreatedDate)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.query.DTEndCreatedDate = ''
          }
      },
    getList() {
      const parem={
        Page: 1,
        Perpage: 10,
        OrderBy: {"DTCreatedDate":-1},
        Search:{}
      }
      if (this.query.strType=="损毁"){
        parem.Search["StrFineReason"]="Broken";
      }else if(this.query.strType=="丢失"){
        parem.Search["StrFineReason"]="Lost";
      }
      if(this.query.DTStartCreatedDate!="" && this.query.DTStartCreatedDate!=null){
        parem.Search["DTStartCreatedDate"]=this.query.DTStartCreatedDate + 'T00:00:00.999Z'
      }
      if(this.query.DTEndCreatedDate!="" && this.query.DTEndCreatedDate!=null ){
        parem.Search["DTEndCreatedDate"]=this.query.DTEndCreatedDate + 'T23:59:59.999Z'
      }
      if(this.query.StrLibraryID!="" && this.query.StrLibraryID!=null ){
        parem.Search["StrLibraryID"]=this.query.StrLibraryID
      }
      this.$axios({
        url:this.localpath+'finerecords/search',
        method:'post',
        //发送格式为json
        data:JSON.stringify(parem),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType':'json',
              'Authorization': localStorage.getItem("token")
            }}).then(response => {
        if (response.data.ok) {
          this.list = response.data.msg
          this.total=response.data.total
          this.listLoading = false
        }
      })
    },
    pageSizeChange(Perpage) {
      this.query.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.query.Page = Page
      this.getList()
    },
    // 获取书屋
  getSWList() {
    this.$axios({
      url: this.localpath + 'librarys',
      method: 'get',
      //发送格式为json
      headers:
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem("token")
          }
    }).then((res) => {
      if (res.data.ok) {
        this.swList = res.data.msg
      }else{
        this.$message({
          message:res.data.msg,
          type: 'error'
        })
      }
    })
  },
    btnDel(row) {
      this.$confirm('是否撤销?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$axios({
          url: this.localpath + 'finerecords/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
  //   reset() {
  //     this.query = {}
  //   },
  //   handleDownload() {
  //     this.downloadLoading = true
  //     const param = {
  //       isExp: 1
  //     }
  //     fetchDSSHList(param).then(response => {
  //       if (response.code === 0) {
  //         this.downloadLoading = false
  //         const aLink = document.createElement('a')
  //         const url = this.Url + '/' + response.map.path + response.map.fileName
  //         aLink.href = url
  //         aLink.click()
  //       }
  //     })
  //   }
  }
}
</script>

