<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="btnQuery"
      >查询</el-button>
      <el-button
          class="filter-item"
          size="mini"
          type="warning"
          icon="el-icon-refresh"
          style="margin-left:4px"
          @click="reset"
      >重置</el-button>
      <el-button
          v-if="!downloadLoading"
          size="mini"
          class="filter-item"
          type="info"
          style="margin-left:4px"
          icon="el-icon-download"
          @click="handleDownload"
      >导出</el-button>
      <el-button
          v-if="downloadLoading"
          size="mini"
          :loading="downloadLoading"
          class="filter-item"
          type="info"
          style="margin-left:6px"
          icon="el-icon-download"
      >正在导出</el-button>
    </div>
    <el-table
        ref="tableForm"
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
        @selection-change="selectionChange"
    >
      <el-table-column show-overflow-tooltip label="期刊名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrJournalTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="期号"
          prop="id"
          align="center"
          show-overflow-tooltip
      >
        <template v-slot="{row}">
          <span>{{ row.StrIssue }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="年号" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrYearNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="邮发代号" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrMailingCode }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="出版单位" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrPublisher }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="ISSN" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrISSN }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="类型" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="期刊条码" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrJournalBarCode }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="价格" align="center">
        <template v-slot="{row}">
          <span>{{ row.FloatPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="书屋位置" align="center">
        <template v-slot="{row}">
          <!-- strCollectionLocation -->
          <span>{{ row.StrLibraryName }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="入库时间" align="center" width="90">
        <template v-slot="{row}">
          <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="操作员" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrCreatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="230"
          class-name="small-padding fixed-width"
          fixed="right"
      >
        <template v-slot="{row,$index}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row,$index)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          v-show="total>=5"
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <el-dialog title="编辑" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-tabs>
        <el-tab-pane label="期刊基本信息">
          <el-form
              ref="dataForm"
              :rules="rules"
              :model="journalmodel"
              label-width="100px"
              size="small"
          >
            <el-row :gutter="20">
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="订阅年" prop="title">
                  <el-date-picker
                      v-model="journalmodel.strYear"
                      type="year"
                      placeholder="选择年"
                      value-format="yyyy"
                      disabled
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="邮发代号" prop="title">
                  <el-input v-model="journalmodel.strPostcode" :readonly="true" />
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="CN" prop="title">
                  <el-input v-model="journalmodel.StrCN" :readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="ISSN" prop="title">
                  <el-input v-model="journalmodel.StrISSN" :readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="报刊名称" prop="title">
                  <el-input v-model="journalmodel.StrJournalTitle" :readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="出版单位" prop="title">
                  <el-input v-model="journalmodel.StrPublisher" :readonly="true"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="刊期类型" prop="title">
                  <el-select v-model="journalmodel.StrTypeName" class="filter-item" placeholder="请选择" disabled>
                    <el-option v-for="item in JournalTypeList" :key="item.StrID" :label="item.StrName" :value="item.StrName" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="订阅期限" prop="StrPublishCycle">
                  <el-select v-model="journalmodel.StrPublishCycle" class="filter-item" placeholder="请选择" disabled>
                    <el-option v-for="item in qxOptions" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="此本期刊信息">
          <el-form
              ref="dataForm"
              :rules="rules"
              :model="journalmodel"
              label-width="100px"
              size="small"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="第几期" prop="title">
                  <el-input v-model="journalmodel.StrIssue" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="主题" prop="title">
                  <el-input v-model="journalmodel.StrSubject" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="装订" prop="title">
                  <el-input v-model="journalmodel.StrBinding" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期刊条码" prop="title">
                  <el-input v-model="journalmodel.StrJournalBarCode" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="updateData">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
    <el-dialog title="期刊查询" width="28%" v-model="dialogQKCXVisible" :close-on-click-modal="false">
      <el-row :gutter="20">
        <el-form size="small" label-width="100px" :model="listQuery">
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="邮发代号" prop="title">
              <el-input v-model="listQuery.StrMailingCode" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="ISSN" prop="title">
              <el-input v-model="listQuery.StrISSN" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="CN" prop="title">
              <el-input v-model="listQuery.StrCN" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="期刊名" prop="title">
              <el-input v-model="listQuery.StrJournalTitle" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="期号" prop="title">
              <el-input v-model="listQuery.StrIssue" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="期刊条码" prop="title">
              <el-input v-model="listQuery.StrJournalBarCode" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="刊期类型" prop="StrTypeName">
              <el-select v-model="listQuery.StrTypeName" class="filter-item" placeholder="请选择" >
                <el-option v-for="item in JournalTypeList" :key="item.StrID" :label="item.StrName" :value="item.StrName" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="dialogQKCXVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="btnQuery">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'journalregister',
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 5,
        intNotOn: "-1",
        Sort:"1",
        OrderBy: "StrJournalTitle",
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑'
      },
      rules: {
      },
      JournalTypeList:{},
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      dialogQKCXVisible: false,
      formModel: {},
      swList: [],
      activeName: 'first',
      dialogHBQKVisible: false,
      formQKModel: {},
      formCBModel: {},
      qxOptions: [
        {
          label: '全年',
          value: '全年'
        },
        {
          label: '半年',
          value: '半年'
        },
        {
          label: '季度',
          value: '季度'
        }
      ],
      hbqkList: [],
      qklxList: [],
      isChecked: false,
      isIntBind: false,
      journalmodel: {},
      isCode: true,
      isCodeNumber: true,
      Url: ''
    }
  },
  created() {
    this.getjournallist()
    this.getJournalTypeList()
    // this.getSWList()
    // this.getQKLXList()
    // this.Url = process.env.VUE_APP_BASE_API
  },
  methods: {
    //获得期刊登记列表
    getjournallist(){
      this.$axios({
        url: this.localpath + 'journals/search',
        method: 'post',
        data:JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.listLoading = false
          this.total = res.data.total
        }
      })
    },
    // 获取期刊类型列表
    getJournalTypeList() {
      const param={
        Page: 1,
        Perpage: 999,
        intNotOn:"1",
        Sort:"-1",
        OrderBy: "DTCreatedDate"
      }
      this.$axios({
        url: this.localpath + 'journaltypes/search',
        method: 'post',
        data:JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok){
          this.JournalTypeList = res.data.msg
        }else{
          this.$message({
            message:res.data.msg,
            type: 'error'
          })
        }
      })
    },
    selectionChange(selection) {
      const temp = []
      selection.forEach(item => {
        temp.push({
          strId: item.strId,
          strIssue: item.strIssue,
          strJournalId: item.strJournalId
        })
      })
      this.hbqkList = temp
    },
    changeIsChecked(val) {
      this.isCode = !val
    },
    changeIsIntBind(val) {
      this.isCodeNumber = !val
    },
    // 编辑
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.journalmodel = row
    },
    // 编辑确定
    updateData() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axios({
            url: this.localpath + 'journals/'+this.journalmodel.StrID,
            method: 'patch',
            data:JSON.stringify(this.journalmodel),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
              this.dialogFormVisible = false
            }else{
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getjournallist()
          })
        }
      })
    },

    // 删除
    handleDelete(row) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          url: this.localpath + 'journals/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }else{
            this.$notify({
              message: res.data.msg,
              type: 'error',
              duration: 5000
            })
          }
          this.getjournallist()
        })
      })
    },
    // 查询
    btnQuery() {
      if (!this.dialogQKCXVisible) {
        this.dialogQKCXVisible = true
      } else {
        this.listQuery.Search={}
        if (this.listQuery.StrMailingCode!=undefined){
          this.listQuery.Search["StrMailingCode"]=this.listQuery.StrMailingCode
        }
        if (this.listQuery.StrISSN!=undefined){
          this.listQuery.Search["StrISSN"]=this.listQuery.StrISSN
        }
        if (this.listQuery.StrCN!=undefined){
          this.listQuery.Search["StrCN"]=this.listQuery.StrCN
        }
        if (this.listQuery.StrJournalTitle!=undefined){
          this.listQuery.Search["StrJournalTitle"]=this.listQuery.StrJournalTitle
        }
        if (this.listQuery.StrTypeName!=undefined){
          this.listQuery.Search["StrTypeName"]=this.listQuery.StrTypeName
        }
        if (this.listQuery.StrIssue!=undefined){
          this.listQuery.Search["StrIssue"]=this.listQuery.StrIssue
        }
        if (this.listQuery.StrJournalBarCode!=undefined){
          this.listQuery.Search["StrJournalBarCode"]=this.listQuery.StrJournalBarCode
        }
        this.getjournallist()
        this.dialogQKCXVisible = false
      }
    },
    // 重置
    reset() {
      this.listQuery = {
        Page: 1,
        Perpage: 5,
        intNotOn: "1",
        Sort:"-1",
        OrderBy: "StrJournalTitle",
      }
      this.getjournallist()
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      this.$axios({
        url:this.localpath+'journals/export',
        method:'post',
        //发送格式为json
        data:JSON.stringify(this.listQuery),
        responseType:'blob',
        headers:
            {
              'Content-Type': 'application/json',
              'dataType':'json',
              'Authorization': localStorage.getItem("token")
            }}).then(res => {
        const blob = new Blob([res.data],{type: 'application/vnd.ms-excel'}); //处理文档流
        var data = new Date()
        let timem = data.getTime();
        const fileName = "期刊登记"+timem+".xlsx";
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(()=>{
        this.downloadLoading = false
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getjournallist()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getjournallist()
    }
  }
}
</script>

<style scoped>
.filter-container{
  display: inline-block;
}
.el-form--inline .el-form-item{
  margin-right: 0;
}
.el-button{
  margin-bottom: 10px;
  margin-left: 10px;
}
.el-button:first-child{
  margin-left: 0;
}
</style>
