<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item label="部门">
            <el-select v-model="listQuery.Search.StrDepartmentID" placeholder="请选择" @change="GradeChange">
              <el-option
                  v-for="item in GradeList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="小组">
            <el-select v-model="listQuery.Search.StrGroupID" placeholder="请选择">
              <el-option
                  v-for="item in ClassList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.Search.DTStartTime"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.Search.DTEndTime"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="姓名" align="center">
        <template v-slot="{row}">
          <span>{{ row.Reader.StrName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="部门" align="center">
        <template v-slot="{row}">
          <span>{{ row.Reader.StrDepartmentName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="小组" align="center">
        <template v-slot="{row}">
          <span>{{ row.Reader.StrGroupName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="积分" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntIntegrallCount }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="total" style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name:'IntegralStatistics',
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        Search:{}
      },
      total: 0,
      GradeList: [],
      ClassList:[],
      Url: ''
    }
  },
  created() {
    this.getGrade()
    this.getList()
  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date( this.listQuery.Search.DTStartTime)
          const day2 = new Date( this.listQuery.Search.DTEndTime)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.Search.DTEndTime = ''
          }
      },

    getList() {
        const parem ={
            Page: 1,
            Perpage: 10,
            Search:{}
        }
        var end = this.listQuery.Search.DTEndTime
        if (end!==undefined&&end!=null) {
            parem.Search.DTStartCreatedDate = this.listQuery.Search.DTStartTime+"T00:00:00.999Z"
            parem.Search.DTEndCreatedDate = this.listQuery.Search.DTEndTime+"T23:59:59.999Z"
        }
      this.listLoading=true
      this.$axios({
        url: this.localpath + 'organizations/integralcount',
        method: 'post',
        data: JSON.stringify(parem),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total=res.data.total
          this.listLoading = false
        }
      })
    },
    //重置
    reset(){
      this.listQuery={
        Page: 1,
        Perpage: 10,
        Search:{}
      }
      this.getList();
    },
    // 获取部门
    getGrade() {
      const param = {
        OrderBy: {"DTCreatedDate":-1},
        Page: 1,
        Perpage: 10,
      }
      this.$axios({
        url: this.localpath + 'departments/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.GradeList = res.data.msg
        }
      })
    },
    // 根据部门获取小组
    GradeChange(val) {
      const param = {
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        Search:{"StrDepartmentID":val},
        Page: 1,
        Perpage: 999,
      }
      this.$axios({
        url: this.localpath + 'groups/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.ClassList = res.data.msg
        }
      })
    },
    // 分页
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

