<template>
  <div class="container">
    <div style="padding:30px 0;text-align:center">
      <el-form class="demo-form-inline" size="medium" :inline="true">
        <el-form-item>
          <div style="display:flex;">
            <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">读者卡号</div>
            <el-input v-model="strReaderCardNumber" @keyup.enter.prevent="" />
          </div>
        </el-form-item>
        <el-form-item>
          <div style="display:flex;">
            <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:150px;font-size: 16px;">归还天数</div>
            <div style="width: 240px">
            <el-slider
                :min="1"
                :max="30"
                v-model="returnday">
            </el-slider>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div style="display:flex;">
            <el-button type="success" style="margin-left: 100px;font-size: 16px" @click="borrowBook">模拟借书</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div style="display: flex;justify-content:space-around">
      <el-card class="box-card" shadow="hover" v-loading="listLoading">
        <div slot="header" class="clearfix">
          <span>图书列表</span>
        </div>
        <el-divider></el-divider>
        <div class="text item" style="height: 450px;overflow: auto">
          <el-tree
              ref="bookListTree"
              :data="bookList"
              show-checkbox
              @check="bookListNodeClick"
              node-key="StrNodeId"
              :props="defaultProps"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              empty-text=""
              :indent="0"
              default-expand-all/>
          <div style="display: flex;justify-content: space-around">
          <el-pagination
              background
              small
              hide-on-single-page
              :total="total"
              :current-page="listQuery.page"
              :page-size="listQuery.perPage"
              layout="prev,pager,next,jumper"
              @current-change="currPageChange"/>
          </div>
        </div>
      </el-card>

      <el-card class="box-card"  shadow="hover">
        <div slot="header" class="clearfix">
          <span>已选择的图书列表</span>
          <el-button style="float: right;" type="primary" @click="clearchoosebook">清空列表</el-button>
        </div>
        <el-divider></el-divider>
        <div class="text item" style="max-height: 450px;overflow: auto">

          <div v-for="book in chooseshowbook" :key="book" style="margin-left: 20px;font-size: 14px;line-height: 26px">
            {{ book.title }}
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "fastBorrowAndReturn",
  data(){
    return{
      strReaderCardNumber: '',
      returnday: 0,
      strBookBarcodes: '',

      listQuery: {
        page: 1,
        perPage: 16,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {
          IsBorrowed: false,
          IsBroken: false,
          IsLost: false,
        }
      },
      total: 100,

      listLoading: true,
      bookList: [],
      pageNum: 1,

      choose:[
        {
          page:1,  //首页有必要先加载进来
          books:[
            // {
            //    code: '',
            //   title:''
            // }
          ]
        }
      ],
      chooseshowbook:[]
    }
  },
  mounted() {
    this.getBookList()
  },
  methods:{
    borrowBook(){
      if(this.strReaderCardNumber===''){
        this.$message({
          message: '请输入读者卡号',
          type: "error",
        });
        return
      }else if(this.chooseshowbook.length===0){
        this.$message({
          message: '请选择图书',
          type: "error",
        });
        return
      }

      this.strBookBarcodes=''
      for(var i in this.choose){
        for(var j in this.choose[i].books){
          console.log(this.choose[i].books[j].code)
          this.strBookBarcodes=this.strBookBarcodes + ','+this.choose[i].books[j].code
        }
      }
      this.strBookBarcodes=this.strBookBarcodes.slice(1)

      this.book = {
        StrReaderCardNumber: this.strReaderCardNumber,
        StrBookBarcodes: this.strBookBarcodes,
        ReturnDays: this.returnday
      };
      this.$axios({
        url: this.localpath + "books/quickBorrowReturn",
        method: "post",
        //发送格式为json
        data: JSON.stringify(this.book),
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.$message({
            message: '模拟借还成功',
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //清空列表
    clearchoosebook(){
      this.choose = [
        {
          page: this.listQuery.page,
          books:[]
        }
      ]
      this.chooseshowbook = []

      this.$refs.bookListTree.setCheckedKeys([], true)
    },
    bookListNodeClick(){
      var chooseindex
      for(var i in this.choose){
        if(this.choose[i].page===this.listQuery.page){
          this.choose[i].books=[]
          chooseindex = i
          break;
        }
      }
      var menurange = this.$refs.bookListTree.getCheckedKeys(true)

      //点击添加或者删除
        // eslint-disable-next-line no-redeclare
      for(var i in menurange){
        const param = {code: menurange[i].split('|')[0],title: menurange[i].split('|')[1]}
        this.choose[chooseindex].books.push(param)
      }

      //展示
      this.chooseshowbook = []
        // eslint-disable-next-line no-redeclare
      for(var i in this.choose){
        for(var j in this.choose[i].books){
          this.chooseshowbook.push(this.choose[i].books[j])
        }
      }
    },
    //获取图书
    getBookList() {
      this.$axios({
        url: this.localpath + "books/search",
        method: "post",
        //发送格式为json
        data: JSON.stringify(this.listQuery),
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.total = res.data.total

          this.bookList = []
          var books = res.data.msg

          for(var i in books){
            const param = {label: books[i].StrPositiveTitle,StrNodeId: books[i].StrBookBarcode+'|'+books[i].StrPositiveTitle}
            this.bookList.push(param)
          }

        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
        this.listLoading = false;
      });
    },

    currPageChange(Page) {
      this.listQuery.page = Page;

      this.getBookList();

      var choosepageindex = -1
      for(var i in this.choose){
        if(this.choose[i].page===this.listQuery.page){
          choosepageindex = i
          break;
        }
      }

      if(choosepageindex === -1){
        const param = {page: this.listQuery.page,books: []}
        this.choose.push(param)
      }else{
        var keys = []
        for(var j in this.choose[choosepageindex].books){
          keys.push(this.choose[choosepageindex].books[j].code+'|'+this.choose[choosepageindex].books[j].title)
        }
        this.$refs.bookListTree.setCheckedKeys(keys, true)
      }
    },
  }
}
</script>

<style scoped>
.box-card{
  width: 48%;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}


</style>