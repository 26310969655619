<template>
  <div style="padding:20px">
    <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        style="margin-bottom:10px"
        @click="handleCreate"
    >添加</el-button>
    <div class="custom-tree-container">
      <div class="block">
        <el-tree :props="defaultProps" lazy :load="loadNode" :data="list">
          <template v-slot="{ node }" class="custom-tree-node" @mouseenter="enter(node)" @mouseleave="leave">
            <span>{{ node.label }}</span>
            <span style="margin-left:10px">
              <el-button type="text" icon="el-icon-plus" @click.stop="handleCreate(node)" />
            </span>
            <span style="margin-left:10px">
              <el-button type="text" icon="el-icon-edit" @click.stop="handleUpdate(node)" />
            </span>
            <span style="margin-left:10px">
              <el-button type="text" icon="el-icon-delete" @click.stop="handledelete(node)" />
            </span>
          </template>
        </el-tree>
      </div>
    </div>
    <el-dialog
        :title="textMap[dialogStatus]"
        v-model="dialogVisible"
        width="30%">
      <el-form ref="dataForm" :model="formModel" label-width="100px" :rules="rules" size="small">
        <el-form-item label="节点名" prop="StrName">
          <el-input v-model="formModel.StrName" />
        </el-form-item>
      </el-form>
      <span class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="btnMod">确 定</el-button>
      </span>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'classification',
  inject: ['reload'],
  components: {
  },
  data() {
    return {
      dialogFormVisibleSecond: false,
      styles: {
        paddingTop: '20px',
        paddingBottom: 0,
        marginBottom: '20px'
      },
      list:null,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'StrName'
      },
      dialogStatus:'',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      listQuery: {
        Page: 1,
        Perpage: 999,
        intNotOn:"1",
        Sort:"-1",
        StrParentName:"",
        StrName:"",
        OrderBy: "DTCreatedDate",
      },
      seen: false,
      current: 0,
      dialogVisible: false,
      formModel: {},
      rules: {
        StrName: [
          { required: true, message: '请输入节点名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    three() {
      this.dialogFormVisibleSecond = true
    },
    // 获取学科列表
    getList() {
      const param = {
        Page: 1,
        Perpage: 999,
        intNotOn:"1",
        Sort:"1",
        OrderBy: "DTCreatedDate",
        Search:{
          StrParentName: "Top"
        }
      }
      this.$axios({
        url: this.localpath + 'booktypes/search',
        method: 'post',
        data:JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.list=res.data.msg
        }
      })
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.getList(resolve)
      }
      if (node.level >= 1) {
        this.getIndex(node, resolve)
      }
    },
    // 获取子节点
    getIndex(node, resolve) {
      this.listQuery.Search={}
      this.listQuery.Search["StrParentName"]=node.data.StrName
      this.$axios({
        url: this.localpath + 'booktypes/search',
        method: 'post',
        data:JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          resolve(res.data.msg)
        }
      })
    },
    handleCreate(node){
      this.formModel={}
      this.dialogStatus="create"
      this.dialogVisible=true
      this.formModel.StrParentName=node.data.StrName
    },
    handleUpdate(node){
      this.dialogStatus="update"
      this.dialogVisible=true
      this.formModel=node.data
    },
    handledelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          url: this.localpath + 'booktypes/'+row.data.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }else{
            this.$notify({
              message: res.data.msg,
              type: 'error',
              duration: 5000
            })
          }
         this.getList()
        })
      })
    },
    enter(node) {
      this.seen = true
      this.current = node.id
    },
    leave() {
      this.seen = false
      this.current = null
    },
    // 编辑或修改
    btnMod() {
      if (this.dialogStatus === 'create') {
        this.dialogVisible = true
        this.$axios({
          url: this.localpath + 'booktypes',
          method: 'put',
          data:JSON.stringify(this.formModel),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.formModel = res.data.msg
          }else{
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
        this.dialogVisible=false
        this.getList()
      } else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'booktypes/'+this.formModel.StrID,
              method: 'patch',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
               this.getList()
              }else{
                this.$message({
                  message:res.data.msg,
                  type: 'error'
                })
              }
            })
            this.dialogVisible = false
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
