<template>
    <div class="container">
      <div class="filter-container">
        <el-button
            class="filter-item"
            size="mini"
            type="primary"
            style="margin-bottom:10px"
            icon="el-icon-plus"
            @click="handleCreate"
        >添加</el-button>
      </div>
      <el-table
          v-loading="listLoading"
          :data="list"
          border
          fit
          highlight-current-row
          style="width: 100%;"
      >
        <el-table-column label="公告标题" align="center">
          <template v-slot="{row}">
            <span>{{ row.StrTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公告内容" align="center">
          <template v-slot="{row}">
            <span>{{ row.StrDescription }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发布时间" align="center">
          <template v-slot="{row}">
            <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
          </template>
        </el-table-column>
  
        <!-- <el-table-column label="假期描述" align="center">
          <template v-slot="{row}">
            <span>{{ row.StrDescription }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            fixed="right"
        >
          <template v-slot="{row}">
            <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
            <el-button
                v-if="row.status!='deleted'"
                size="mini"
                type="danger"
                @click="handleDelete(row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <div style="margin-top:50px;text-align:right;">
        <el-pagination
            background
            :total="total"
            :current-page="listQuery.Page"
            :page-size="listQuery.Perpage"
            :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
            layout="prev,pager,next,jumper,sizes,total"
            @size-change="pageSizeChange"
            @current-change="currPageChange"
        />
      </div>
      <el-dialog width="30%" :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
        <el-form
            ref="dataForm"
            :rules="rules"
            :model="formModel"
            label-position="left"
            label-width="100px"
            style="margin-left:40px;"
            size="small"
        >
          <el-form-item label="公告标题" prop="StrTitle">
            <el-input v-model="formModel.StrTitle" placeholder="请输入公告标题" />
          </el-form-item>
          <el-form-item label="公告内容" prop="StrDescription">
            <el-input v-model="formModel.StrDescription" placeholder="请输入公告内容" type="textarea" />
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
        <div style="float: right">
          <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
        </div>
        <p style="color: #ffffff">空行</p>
      </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name:'Holiday',
    data() {
      return {
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          Page: 1,
          Perpage: 10,
          OrderBy: {"DTCreatedDate":-1},
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          update: '编辑',
          create: '添加'
        },
        rules: {
          StrTitle: [
            { required: true, message: '请输入公告标题', trigger: 'blur' }
          ],
          StrDescription: [
            { required: true, message: '请输入公告内容', trigger: 'blur' }
          ]
        },
        formModel: {}
      }
    },
    created() {
      this.getList()
    },
    methods: {
      // 获取假期列表
      getList() {
        this.$axios({
          url: this.localpath + 'announcement/search',
          method: 'post',
          data: JSON.stringify(this.listQuery),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.list = res.data.msg
            this.total = res.data.total
            this.listLoading = false
          }
        })
      },
      // 添加
      handleCreate() {
        this.formModel = {}
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      // 确认添加或修改
      btnAddorMod() {
        if (this.dialogStatus === 'create') {
          this.$refs['dataForm'].validate(valid => {
            if (valid) {
              this.$axios({
                url: this.localpath + 'announcement',
                method: 'put',
                data:JSON.stringify(this.formModel),
                //发送格式为json
                headers:
                    {
                      'Content-Type': 'application/json',
                      'dataType': 'json',
                      'Authorization': localStorage.getItem("token")
                    }
              }).then((res) => {
                if (res.data.ok) {
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  })
                }else{
                  this.$message({
                    message:res.data.msg,
                    type: 'error'
                  })
                }
                this.getList()
              })
              this.dialogFormVisible = false
            }
          })
        }
        else {
          this.$refs['dataForm'].validate(valid => {
            if (valid) {
              this.$axios({
                url: this.localpath + 'announcement/'+this.formModel.StrID,
                method: 'patch',
                data:JSON.stringify(this.formModel),
                //发送格式为json
                headers:
                    {
                      'Content-Type': 'application/json',
                      'dataType': 'json',
                      'Authorization': localStorage.getItem("token")
                    }
              }).then((res) => {
                if (res.data.ok) {
                  this.$message({
                    message: '编辑成功',
                    type: 'success'
                  })
                }else{
                  this.$message({
                    message: res.data.msg,
                    type: 'error'
                  })
                }
                this.getList()
              })
              this.dialogFormVisible = false
            }
          })
        }
      },
      // 编辑
      handleUpdate(row) {
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
        this.formModel = row
      },
      handleDelete(row) {
        this.$confirm('确定要删除公告吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.$axios({
            url: this.localpath + 'announcement/'+row.StrID,
            method: 'delete',
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$notify({
                message: '删除成功',
                type: 'success',
                duration: 2000
              })
            }else{
              this.$notify({
                message:res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      pageSizeChange(Perpage) {
        this.listQuery.Perpage = Perpage
        this.getList()
      },
      currPageChange(Page) {
        this.listQuery.Page = Page
        this.getList()
      }
    }
  }
  </script>
  
  