<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <!--          <el-form-item label="订阅时间">-->
          <!--            <el-date-picker-->
          <!--                v-model="listQuery.strStartDate"-->
          <!--                type="date"-->
          <!--                placeholder="开始时间"-->
          <!--                value-format="yyyy-MM-dd"-->
          <!--            />-->
          <!--            <el-date-picker-->
          <!--                v-model="listQuery.strEndDate"-->
          <!--                type="date"-->
          <!--                style="margin-left:4px"-->
          <!--                placeholder="结束时间"-->
          <!--                value-format="yyyy-MM-dd"-->
          <!--            />-->
          <!--          </el-form-item>-->
          <el-form-item label="邮发代号" style="margin-left:12px">
            <el-input v-model="listQuery.StrMailingCode" @keydown.enter="handleFilter"/>
          </el-form-item>
          <el-form-item>
            <el-radio v-model="listQuery.intLogOff" style="margin:0 10px" label="-1">全部</el-radio>
            <el-radio v-model="listQuery.intLogOff" style="margin-right:10px" label="0">未注销</el-radio>
            <el-radio v-model="listQuery.intLogOff" style="margin-right:10px" label="1">注销</el-radio>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="handleFilter"
            >查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="warning"
                size="mini"
                icon="el-icon-refresh"
                style="margin-left:8px"
                @click="reset"
            >重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleCreate"
            >添加期刊订阅
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                v-if="!downloadLoading"
                class="filter-item"
                type="info"
                icon="el-icon-download"
                size="mini"
                style="margin-left:8px"
                @click="handleDownload"
            >导出
            </el-button>
            <el-button
                v-if="downloadLoading"
                size="mini"
                :loading="downloadLoading"
                class="filter-item"
                type="info"
                style="margin-left:6px"
                icon="el-icon-download"
            >正在导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="qkdyLoading"
        border
        fit
        highlight-current-row
        style="width: 100%;"
        :data="qkdyList"
    >
      <el-table-column label="期刊名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrJournalTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属年度" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrYearNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="邮发代号" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrMailingCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="CN" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrCN }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ISSN" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrISSN }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出版单位" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrPublisher }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作员" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrCreatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否注销" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntLogOff === 0 ? '否' : '是' }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="320"
          class-name="small-padding fixed-width"
          fixed="right"
      >
        <template v-slot="{row,$index}">
          <el-button type="info" style="background:orange;border:none;width:56px;margin-right:5px" size="mini"
                     @click="handleUpdate(row)">编辑
          </el-button>
          <el-button type="success" size="mini" style="width:76px;margin-right:5px" :disabled="row.IntLogOff===1"
                     @click="btnKDDJ(row, 'tableDj')">刊到登记
          </el-button>
          <el-button :type="row.intLogOff !== 0?'info':'warning'" size="mini" style="width:76px;margin-right:5px"
                     @click="cancelled(row, row.IntLogOff===0?1:0)">{{ row.IntLogOff !== 0 ? '取消注销' : '注销' }}
          </el-button>
          <el-button
              size="mini"
              type="danger"
              style="width:58px"
              @click="handleDelete(row,$index)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:20px;text-align:right;">
      <el-pagination
          background
          :total="listQuery.total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="journallistpageSizeChange"
          @current-change="journallistcurrPageChange"
      />
    </div>

    <el-dialog :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <!-- 第一个表单 -->
      <el-form label-width="110px" size="small">
        <el-row :gutter="20">
          <!-- @submit.native.prevent -->
          <el-form-item label="检索字段">
            <el-radio label="StrISSN" v-model="radioISSN">ISSN</el-radio>
          </el-form-item>
          <el-form-item label="检索内容">
            <el-input size="small" v-model="listFindQuery.issn"/>
          </el-form-item>
          <el-form-item label-width="30px">
            <el-button size="mini" type="primary" @click="findJournal" v-show="!findByStrIssnLoading">查询</el-button>
            <el-button
                size="mini"
                v-show="findByStrIssnLoading"
                type="primary"
                :loading="true">查询中
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <el-form
          ref="dataForm"
          :model="formModel"
          label-width="100px"
          size="small"
          :rules="rules">
        <el-row :gutter="21">
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="订阅年" prop="StrYearNumber">
              <el-date-picker
                  v-model="formModel.StrYearNumber"
                  type="year"
                  placeholder="选择年"
              />
            </el-form-item>
          </el-col>


          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="价格" prop="FloatPrice">
              <el-input v-model="formModel.FloatPrice"/>
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="订阅期限" prop="StrPublishCycle">
              <el-select v-model="formModel.StrPublishCycle" class="filter-item" placeholder="请选择">
                <el-option v-for="item in qxOptions" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="数量" prop="IntDuplicate">
              <el-input v-model="formModel.IntDuplicate" @input="calculate"/>
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="ISSN" prop="StrISSN">
              <el-input v-model="formModel.StrISSN"/>
            </el-form-item>
          </el-col>

          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="邮发代号" prop="StrMailingCode">
              <el-input v-model="formModel.StrMailingCode"/>
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="期刊名称" prop="StrJournalTitle">
              <el-input v-model="formModel.StrJournalTitle"/>
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="出版单位" prop="StrPublisher">
              <el-input v-model="formModel.StrPublisher"/>
            </el-form-item>
          </el-col>


          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="刊期类型" prop="StrTypeName">
              <el-select v-model="formModel.StrTypeName" class="filter-item" placeholder="请选择">
                <el-option v-for="item in JournalTypeList" :key="item.StrID" :label="item.StrName"
                           :value="item.StrName" @click="typeOptionClick(item.StrID)"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
            <el-form-item label="CN" prop="StrCN">
              <el-input v-model="formModel.StrCN"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="btnAddorMod" style="margin-left:6px">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <el-dialog title="刊到登记" v-model="dialogKDDJVisible" top="7vh" :close-on-click-modal="false">
      <el-tabs v-model="activeName">
        <el-tab-pane label="期刊基本信息" name="first">
          <el-form size="small" label-width="100px" :model="formKDDJModel">
            <el-row :gutter="20">
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="年" prop="strYear">
                  <el-input v-model="formKDDJModel.StrYearNumber"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="邮发代号">
                  <el-input v-model="formKDDJModel.StrMailingCode"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="CN">
                  <el-input v-model="formKDDJModel.StrCN"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="报刊名称">
                  <el-input v-model="formKDDJModel.StrJournalTitle"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="出版单位">
                  <el-input v-model="formKDDJModel.StrPublisher"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="ISSN">
                  <el-input v-model="formKDDJModel.StrISSN"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="刊期">
                  <el-select v-model="formKDDJModel.StrTypeName" class="filter-item" placeholder="请选择">
                    <el-option v-for="item in qklxList" :key="item.StrID" :label="item.StrName" :value="item.StrID"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="订阅期限">
                  <el-select v-model="formKDDJModel.StrPublishCycle" class="filter-item" placeholder="请选择">
                    <el-option v-for="item in qxOptions" :key="item.value" :label="item.label" :value="item.value"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="本期信息" name="second">
          <el-form ref="qkdyForm" size="small" label-width="100px" :model="formQKDJModel" :rules="qkdyRules">
            <el-row :gutter="20">
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="期号" prop="StrIssue">
                  <el-input v-model="formQKDJModel.StrIssue"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="主题" prop="StrSubject">
                  <el-input v-model="formQKDJModel.StrSubject"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="条码" prop="StrJournalBarCode">
                  <el-input v-model="formQKDJModel.StrJournalBarCode"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="装订" prop="StrBinding">
                  <el-input v-model="formQKDJModel.StrBinding"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="书屋位置" prop="StrLibraryID">
                  <el-select v-model="formQKDJModel.StrLibraryID" placeholder="请选择书屋位置" :disabled="isSelect"
                             @change="LibraryChange">
                    <el-option
                        v-for="item in swList"
                        :key="item.StrName"
                        :label="item.StrName"
                        :value="item.StrID"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item label="书架">
                  <el-select v-model="formQKDJModel.StrBookshelfID" placeholder="请选择书架" filterable>
                    <el-option
                        v-for="item in sjList"
                        :key="item.StrID"
                        :label="item.StrName"
                        :value="item.StrID"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-input v-model="formQKDJModel.StrJournalRegistrationID" type="hidden"/>
              <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
                <el-form-item>
                  <el-button type="primary" @click="btnKDDJ()">刊到登记</el-button>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <el-table
          v-loading="kddjLoading"
          border
          fit
          highlight-current-row
          style="width: 100%;"
          :data="kddjList"
      >
        <el-table-column
            label="期号"
            prop="id"
            align="center"
        >
          <template v-slot="{row}">
            <span>{{ row.StrIssue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="主题"
            prop="id"
            align="center"
        >
          <template v-slot="{row}">
            <span>{{ row.StrSubject }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="期刊条码"
            prop="id"
            align="center"
        >
          <template v-slot="{row}">
            <span>{{ row.StrJournalBarCode }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:50px;text-align:right;">
        <el-pagination
            background
            :total="journallist.total"
            :current-page="journallist.Page"
            :page-size="journallist.Perpage"
            :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
            layout="prev,pager,next,jumper,sizes,total"
            @size-change="pageSizeChange"
            @current-change="pagePageChange"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'journalsubscribe',
  data() {
    return {
      total: 0,
      listLoading: false,
      //期刊订阅列表的list
      listQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      },
      //新增时根据ISSN查询
      findByStrIssnLoading: false,
      radioISSN: 'StrISSN',
      listFindQuery: {
        issn: ''
      },
      journallisttotal: 0,
      //点击刊到登记的list
      journallist: {
        total: 0,
        Page: 1,
        Perpage: 5,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      },
      sjList: '',
      dialogFormVisible: false,
      // 查看登记
      dialogFormDJVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      JournalTypeList: {},
      rules: {
        StrMailingCode: [
          {required: true, message: '请输入邮发代号', trigger: 'blur'}
        ],
        StrISSN: [
          {required: true, message: '请输入ISSN', trigger: 'blur'}
        ],
        StrJournalTitle: [
          {required: true, message: '请输入报刊名称', trigger: 'blur'}
        ],
        StrYearNumber: [
          {required: true, message: '请选择订阅年', trigger: 'blur'}
        ],
        StrType: [
          {required: true, message: '请选择刊期', trigger: 'change'}
        ],
        StrPublishCycle: [
          {required: true, message: '请选择订阅期限', trigger: 'change'}
        ],
        FloatPrice: [
          {required: true, message: '请输入价格', trigger: 'blur'}
        ],
        IntDuplicate: [
          {required: true, message: '请输入数量', trigger: 'blur'}
        ]
      },
      qkdyRules: {
        StrIssue: [
          {required: true, message: '请输入期号', trigger: 'blur'}
        ],
        StrSubject: [
          {required: true, message: '请输入主题', trigger: 'blur'}
        ],
        StrLibraryID: [
          {required: true, message: '请选择书屋位置', trigger: 'change'}
        ],
        StrJournalBarCode: [
          {required: true, message: '请输入图书条码', trigger: 'blur'}
        ]
      },
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      qxOptions: [
        {
          label: '全年',
          value: '全年'
        },
        {
          label: '半年',
          value: '半年'
        },
        {
          label: '季度',
          value: '季度'
        }
      ],
      dialogKDDJVisible: false,
      tabPosition: 'left',
      formModel: {},
      formKDDJModel: {},
      qkdyLoading: true,
      qkdyList: [],
      totalMoney: 0,
      qkdjList: [],
      qkdjLoading: false,
      swList: [],
      query: {},
      kddjLoading: true,
      kddjList: [],
      activeName: 'first',
      formQKDJModel: {},
      strJournalId: '',
      dateQuery: {},
      qklxList: [],
      isCode: true,
      kddjQuery: {
        pageIndex: 1,
        pageSize: 5,
        strJournalId: ''
      },
      Url: ''
    }
  },
  created() {
    this.getList()
    this.getJournalTypeList()
    this.getLibraryList()
    // this.getSWList()
    // this.getQKLXList()
    // this.Url = process.env.VUE_APP_BASE_API
  },
  methods: {
    // 注销
    cancelled(row) {
      this.$confirm('确定注销吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          url: this.localpath + 'journalregistrations/' + row.StrID + '/status',
          method: 'patch',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '注销未成功'
        })
      })
    },
    // 获取期刊订阅列表
    getList() {
      const param = {
        Page: this.listQuery.Page,
        Perpage: this.listQuery.Perpage,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      }
      param.Search = {}
      if (this.listQuery.intLogOff != "-1" && this.listQuery.intLogOff != undefined) {
        param.Search["IntLogOff"] = parseInt(this.listQuery.intLogOff)
      }
      if (this.listQuery.StrMailingCode != undefined) {
        param.Search["StrMailingCode"] = this.listQuery.StrMailingCode
      }
      this.$axios({
        url: this.localpath + 'journalregistrations/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(response => {
        if (response.data.ok) {
          this.qkdyList = response.data.msg
          this.listQuery.total = response.data.total
          this.qkdyLoading = false
        }
      })
    },
    // 获取期刊类型列表
    getJournalTypeList() {
      const param = {
        Page: 1,
        Perpage: 999,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate"
      }
      this.$axios({
        url: this.localpath + 'journaltypes/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.JournalTypeList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 获取书屋
    getLibraryList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    changeIsChecked(val) {
      this.isCode = !val
    },
    // 获取书架
    LibraryChange(val) {
      const param = {
        StrLibraryID: val
      }
      //获取书架
      this.$axios({
        url: this.localpath + 'bookshelfs',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.sjList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    typeOptionClick(id){
      this.formModel.StrTypeId=id
    },
    // 重置
    reset() {
      this.listQuery = {
        Page: 1,
        Perpage: 10,
        total: 0,
        Search: {},
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      }
      this.getList()
    },
    // 确定增加或修改
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.formModel.IntDuplicate = parseInt(this.formModel.IntDuplicate)
            this.formModel.FloatPrice = this.formModel.FloatPrice.toString()
            if (this.formModel.StrYearNumber.length !== 4) {
              this.formModel.StrYearNumber = this.formModel.StrYearNumber.getFullYear().toString()
            }
            console.log(this.formModel.StrYearNumber)
            this.$axios({
              url: this.localpath + 'journalregistrations',
              method: 'put',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
                this.getList()
                this.dialogFormVisible = false
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          }
        })
      } else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.formModel.IntDuplicate = parseInt(this.formModel.IntDuplicate)
            this.formModel.FloatPrice = this.formModel.FloatPrice.toString()
            if (this.formModel.StrYearNumber.length !== 4) {
              this.formModel.StrYearNumber = this.formModel.StrYearNumber.getFullYear().toString()
            }
            this.$axios({
              url: this.localpath + 'journalregistrations/' + this.formModel.StrID,
              method: 'patch',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
                this.getList()
                this.dialogFormVisible = false
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          }
        })
      }
    },
    // 查看登记
    rowClick(ID) {
      this.journallist.Search = {}
      this.journallist.Search.StrJournalRegistrationID = ID
      this.dialogFormDJVisible = true
      this.qkdjLoading = true
      this.$axios({
        url: this.localpath + 'journals/search',
        method: 'post',
        data: JSON.stringify(this.journallist),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.qkdjList = res.data.msg
          this.qkdj = ID
          this.qkdjLoading = false
        }
      })
    },
    // 期刊查询
    findJournal() {
      this.findByStrIssnLoading = true
      if (this.listFindQuery.issn === '') {
        this.findByStrIssnLoading = false
        return
      }
      //获取期刊登记的一条数据
      this.$axios({
        url: this.localpath + 'journalregistrations/issnInfo',
        method: 'post',
        data: JSON.stringify(this.listFindQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        var msg = res.data.msg
        if (res.data.ok) {
          // this.list = res.data.msg
          // this.listLoading = false
          // this.total = res.data.total
          this.formModel.StrMailingCode = msg.StrMailingCode
          this.formModel.StrCN = msg.StrCN
          this.formModel.StrISSN = msg.StrISSN
          this.formModel.StrJournalTitle = msg.StrJournalTitle
          this.formModel.StrPublisher = msg.StrPublisher
          this.formModel.StrTypeName = msg.StrTypeName
          this.formModel.FloatPrice = msg.FloatPrice
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
        this.findByStrIssnLoading = false
      })
    },
    calculate(val) {
      const total = this.formModel.fPrice * val
      if (isNaN(total)) {
        this.totalMoney = 0
      } else {
        this.totalMoney = this.formModel.fPrice * val
      }
    },
    // 根据书屋名称查id
    swChange(val) {
      this.swList.forEach(item => {
        if (val === item.strName) {
          this.formQKDJModel.strCollectionLocationId = item.strId
        }
      })
    },
    // 添加
    handleCreate() {
      this.formModel = {}
      this.totalMoney = 0
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    // 查询
    handleFilter() {
      this.qkdyLoading = true
      this.getList()
    },
    // 编辑
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.$axios({
        url: this.localpath + 'journalregistrations/' + row.StrID,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.formModel = res.data.msg

        }
      })
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          url: this.localpath + 'journalregistrations/' + row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              message: res.data.msg,
              type: 'error',
              duration: 5000
            })
          }
          this.getList()
        })
      })
    },
    //获得期刊登记列表
    getjournallist() {
      this.$axios({
        url: this.localpath + 'journals/search',
        method: 'post',
        data: JSON.stringify(this.journallist),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.kddjList = res.data.msg
          this.kddjLoading = false
          this.journallist.total = res.data.total
        }
      })
    },
    // 刊到登记
    btnKDDJ(row) {
      if (row) {
        this.formKDDJModel = row
        this.dialogKDDJVisible = true
        this.journallist.Search = {}
        this.journallist.Search.StrJournalRegistrationID = row.StrID
        this.getjournallist()
        this.$nextTick(() => {
          this.$refs['qkdyForm'].clearValidate()
        })
        this.formQKDJModel.StrJournalRegistrationID = row.StrID
      } else {
        this.$refs['qkdyForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'journals',
              method: 'put',
              data: JSON.stringify(this.formQKDJModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '登记成功',
                  type: 'success'
                })
                const id = this.formQKDJModel.StrJournalRegistrationID
                this.formQKDJModel = {}
                this.formQKDJModel.StrJournalRegistrationID = id
                this.getjournallist()
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          }
        })
      }
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      const param = {
        Page: this.journallist.Page,
        Perpage: this.journallist.Perpage,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      }
      param.Search = {}
      if (this.listQuery.intLogOff != "-1" && this.listQuery.intLogOff != undefined) {
        param.Search["IntLogOff"] = parseInt(this.listQuery.intLogOff)
      }
      if (this.listQuery.StrMailingCode != undefined) {
        param.Search["StrMailingCode"] = this.listQuery.StrMailingCode
      }
      this.$axios({
        url: this.localpath + 'journalregistrations/export',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(param),
        responseType: 'blob',
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //处理文档流
        var data = new Date()
        let timem = data.getTime();
        const fileName = "期刊订阅" + timem + ".xlsx";
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(() => {
        this.downloadLoading = false
      })
    },
    pageSizeChange(Perpage) {
      this.journallist.Perpage = Perpage
      this.getjournallist()
    },
    pagePageChange(Page) {
      this.journallist.Page = Page
      this.getjournallist()
    },
    journallistpageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    journallistcurrPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    },

  }
}
</script>

<style scoped>
.el-button + .el-button {
  margin: 0;
}

.el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
