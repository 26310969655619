<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item label="组织" v-if="$store.state.isAreaManager">
            <el-select v-model="listQuery.StrOrganizationID" placeholder="请选择" @change="getSWList">
              <el-option
                  v-for="item in OrganizationList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="图书ISBN">
            <el-input v-model="listQuery.StrISBN" placeholder="请输入图书ISBN"/>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.DTStartTime"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.DTEndTime"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="图书标题" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrPositiveTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书条码" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrBookBarcode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ISBN" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrISBN }}</span>
        </template>
      </el-table-column>
      <el-table-column label="变更前书屋" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrFromLibraryName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="变更前书架" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrFromShelfName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="变更后书屋" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrToLibraryName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="变更后书架" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrToShelfName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作时间" align="center">
        <template v-slot="{row}">
          <span>{{ new Date(row.DTCreatedDate).toLocaleDateString()}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作员" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrCreatorName}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="书屋" align="center"> -->
      <!-- <template v-slot="{row}">
          <span>{{ row.strCollectionLocation }}</span>
        </template> -->
      <!-- <el-select v-model="listQuery.strCollectionLocation" placeholder="请选择">
          <el-option
            v-for="item in swList"
            :key="item.strId"
            :label="item.strName"
            :value="item.strId"
          />
        </el-select> -->
      <!-- </el-table-column> -->
    </el-table>
    <div v-show="total>=5" style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name:'ReaderLendRanking',
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        Sort:"-1",
        OrderBy: "DTCreatedDate"
      },
      total: 0,
      swList: [],
      Url: ''
    }
  },
  created() {
    //刷新是否是区域管理员
    this.$store.commit('changeIsAreaManager');
    this.getSWList()
    this.getList()
  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.listQuery.DTStartTime)
          const day2 = new Date(this.listQuery.DTEndTime)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.DTEndTime = ''
          }
      },
    getList() {
        // eslint-disable-next-line no-unused-vars
        const parem ={
            Page: 1,
            Perpage: 10,
            Sort:"-1",
            OrderBy: "DTCreatedDate",
            Search:{}
        }
      var end = this.listQuery.DTEndTime
      if (end!==undefined&&end!=null) {
          parem.Search.DTStartCreatedDate = this.listQuery.DTStartTime + "T00:00:00.999Z"
          parem.Search.DTEndCreatedDate = this.listQuery.DTEndTime + "T23:59:59.999Z"
      }
      this.$axios({
        url: this.localpath + 'bookmovelogs/search',
        method: 'post',
        data: JSON.stringify(parem),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total=res.data.total
          this.listLoading = false
        }
      })
    },
    //重置
    reset(){
      this.listQuery={
        Page: 1,
        Perpage: 50
      }
      this.getList();
    },
    // 获取书屋
    getSWList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.swList = res.data.msg
        }
      })
    },
    // 分页
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

