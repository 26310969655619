<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small" :model="query">
          <el-form-item label="书屋">
            <el-select v-model="query.StrLibraryID" placeholder="请选择">
              <el-option
                  v-for="item in swList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="查询条件">
        <el-input v-model.trim="ltQuery.keywords" />
      </el-form-item>
           <el-form-item>
        <el-select v-model="ltQuery.keyName" placeholder="请选择">
          <el-option
              v-for="item in ltQueryList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="query.DTStartCreatedDate"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="query.DTEndCreatedDate"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="warning"
                size="mini"
                icon="el-icon-refresh"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;overflow:auto;"
    >
      <el-table-column
          label="图书条码"
          prop="id"
          align="center"
      >
        <template v-slot="{row}">
          <span>{{ row.StrBookBarcode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="正题名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrPositiveTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属书屋" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrLibraryName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="读者卡号" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrReaderCardNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrReaderName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="罚款金额" align="center">
        <template v-slot="{row}">
          <span>{{ row.FloatFineAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作时间" align="center" width="180">
        <template v-slot="{row}">
          <span>{{ new Date(row.DTCreatedDate).toLocaleString() }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作员" align="center" width="100">
        <template v-slot="{row}">
          <span>{{ row.StrCreatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否处理" align="center" width="90">
        <template v-slot="{row}">
          <span>{{ row.IntStatus==0?"未处理":"已处理" }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="90"
      >
        <template v-slot="{row}">
          <el-button v-if="row.IntStatus==0" type="primary" size="mini" @click="btnDel(row)">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:20px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="query.Page"
          :page-size="query.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <el-dialog width="32%" :title="'罚款确认'" v-model="fine" :close-on-click-modal="false">
      <p style="font-size: 18px">该书刊已超期，需缴纳罚款
        <el-input v-model="formmodel.FloatFineAmount" style="width:20%" />元</p><br>
      <el-checkbox v-model="checked">是否已缴纳罚款</el-checkbox><br>
      <div class="dialog-footer" style="float: right;">
        <el-button size="mini" @click="fine = false">取消</el-button>
        <el-button type="primary" size="mini" v-if="checked" @click="fineandreturn">完成处理</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>
<script>
import {ElMessage} from "element-plus";

export default {
  name:'OverDue',
  data() {
    return {
      list: null,
      listLoading: true,
      rules: {
      },
      fine:false,
      checked:false,
      finemoney:0,
      downloadLoading: false,
      total:0,
      formmodel:{
      },
      query: {
        Page: 1,
        Perpage: 10,
        Sort:"-1",
        OrderBy: "DTCreatedDate"
      },
      swList: [],
      Url: '',
      ltQueryList: [
        {
          name: '图书条码',
          value: 'StrBookBarcode'
        },
        {
          name: '读者卡号',
          value: 'StrReaderCardNumber'
        }
      ],
      ltQuery:{
        keyName:'',
        keywords:''
      }
    }
  },
  created() {
    this.getList()
    this.getSWList()
  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.query.DTStartCreatedDate)
          const day2 = new Date(this.query.DTEndCreatedDate)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.query.DTEndCreatedDate = ''
          }
      },
    getList() {
      const parem={
        Page:this.query.Page,
        Perpage:this.query.Perpage,
        Sort:-1,
        OrderBy: {"DTCreatedDate":-1,"IntStatus":-1},
        Search:{"StrFineReason":"Overdue"}
      }
      if(this.query.DTStartCreatedDate!="" && this.query.DTStartCreatedDate!=null){
        parem.Search["DTStartCreatedDate"]=this.query.DTStartCreatedDate + 'T00:00:00.999Z'
      }
      if(this.query.DTEndCreatedDate!="" && this.query.DTEndCreatedDate!=null ){
        parem.Search["DTEndCreatedDate"]=this.query.DTEndCreatedDate + 'T23:59:59.999Z'
      }
      if(this.query.StrLibraryID!="" && this.query.StrLibraryID!=null ){
        parem.Search["StrLibraryID"]=this.query.StrLibraryID
      }
      if (this.ltQuery.keywords!=""&&this.ltQuery.keyName!=''){
        parem.Search[this.ltQuery.keyName]=this.ltQuery.keywords
      }
      this.$axios({
        url:this.localpath+'finerecords/search',
        method:'post',
        //发送格式为json
        data:JSON.stringify(parem),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType':'json',
              'Authorization': localStorage.getItem("token")
            }}).then(response => {
        if (response.data.ok) {
          this.list = response.data.msg
          console.log(this.list)
          this.total=response.data.total
          this.listLoading = false
        }
      })
    },
    pageSizeChange(Perpage) {
      this.query.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.query.Page = Page
      this.getList()
    },
    // 获取书屋
    getSWList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg
        }else{
          this.$message({
            message:res.data.msg,
            type: 'error'
          })
        }
      })
    },
    btnDel(row) {
      this.fine=true
      this.formmodel.StrID=row.StrID
      this.formmodel.FloatFineAmount=row.FloatFineAmount
    },
    reset(){
      this.ltQuery={
        keywords:'',
        keyName:''
      }
      this.query={
        Page: 1,
        Perpage: 10,
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        DTStartCreatedDate:'',
        DTEndCreatedDate:'',
        StrLibraryID:'',
      }
    },
    //罚款
    fineandreturn(){
      const param={
        "FloatFineAmount":  this.formmodel.FloatFineAmount.toString()
      }
      this.$axios({
        url: this.localpath + 'finerecords/'+ this.formmodel.StrID+'/amount',
        method: 'patch',
        data:JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          //更新IntStatus的值
          var index=this.list.findIndex(li=>li.StrID==res.data.msg.StrID)
          this.list[index]=res.data.msg
          
          this.$message({
            message:"超期处理完成",
            type: 'success'
          })
          this.fine=false
        }else{
          this.$message({
            message:res.data.msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

