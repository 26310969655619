<template>
  <div class="container">
    <el-card shadow="always" style="width:100%;">
      <el-form
          ref="dataForm"
          style="width:50%;"
          label-width="180px"
          size="small"
          :model="formModel"
          :rules="rules"
      >
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="用户名" prop="StrUserName">
              <el-input v-model="formModel.StrUserName" placeholder="请填写用户名"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="密码" prop="StrPassword">
              <el-input v-model="formModel.StrPassword" placeholder="请填写密码" type="password"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="用户昵称" prop="StrNickName">
              <el-input v-model="formModel.StrNickName" placeholder="请填写昵称"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop="StrLibraryName" label="选择默认书屋">
              <el-select v-model="formModel.StrLibraryName" class="filter-item" placeholder="请选择" @change="selectSW">
                <el-option v-for="item in swList" :key="item.StrID" :label="item.StrName" :value="item.StrName"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="头像">
              <el-upload
                  class="avatar-uploader"
                  ref="picimg"
                  :headers="picheaders"
                  :data="uploadtype"
                  :class="{disabled:uploadDisabled}"
                  :action="myurl"
                  list-type="picture-card"
                  :before-upload="beforeUpload"
                  :on-preview="handlePictureCardPreview"
                  :on-change="handleChange"
                  :on-success="handleSuccess"
                  :on-remove="handleRemove"
                  accept=".jpg,.jpeg,.png"
                  :file-list="fileList">
                <i class="el-icon-plus"/>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button size="mini" type="primary" @click="handleSave">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import global from "@/utils/global";
import md5 from "js-md5";
import Compressor from 'compressorjs';
export default {
  name: 'Config',

  data() {
    return {
      formModel: {},
      swList: [],
      xxList: [],
      isShow: 0,
      tempModel: {
        checked: false
      },
      fileList: [],
      picheaders: {},
      rules: {
        StrUserName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        StrLibraryName: [
          {required: true, message: '请选择书屋', trigger: 'blur'}
        ],
        StrPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        StrNickName: [
          {required: true, message: '请填写昵称', trigger: 'blur'}
        ]
      },
      uploadtype: {
        type: 'image/header'
      },
      myurl: '',
      isSelect: false,
      uploading: false,
    }
  },
  computed: {
    uploadDisabled: function () {
      if (this.fileList.length > 0) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    this.myurl = global.syOssUrl + '/upload2Oss'
    this.getSWList()
    this.getList()
  },
  methods: {
    // 获取设置详情
    getList() {
      this.$axios({
        url: this.localpath + 'managers/me',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.formModel = res.data.msg
          if (this.fileList.length == 0) {
            this.fileList.push({url: this.formModel.Avatar})
          }
          localStorage.setItem('schoolimg', this.formModel.Avatar)
        }
      })
    },

    beforeUpload(file) {
      // 判断是图片
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/jpg';
      if (!isJpgOrPng) {
        console.log('上传头像图片只能是 JPG 或 PNG 或 JPEG 格式!');
        return false;
      }
      return new Promise((resolve, reject) => {
        this.now = Date.parse(new Date()) / 1000;
        this.picheaders = {
          'timestamp': this.now,
          'sign': md5('6486b66b41f8df68777eac23' + this.now)
        },
            this.uploading = true
        new Compressor(file, {
          quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    handlePictureCardPreview(file) {
      //默认是下载而不是打开
      window.open(file.url)
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.formModel.Avatar = ''
    },
    handleSuccess(response, file, fileList) {
      this.uploading = false
      if (response.code === 0) {
        this.fileList = fileList
        this.formModel.Avatar = response.msg
      }
    },
    // 获取书屋
    getSWList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 根据书屋id筛选书屋名称
    selectSW(val) {
      this.swList.forEach(item => {
        if (item.StrName === val) {
          this.formModel.StrLibraryID = item.StrID
        }
      })
    },
    // 保存
    handleSave() {
      if(this.uploading){
        this.$message({
          message: "图片正在上传中，请稍后",
          type: 'warning'
        })
        return
      }
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axios({
            url: this.localpath + 'managers/me',
            method: 'patch',
            data: JSON.stringify(this.formModel),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then(res => {
            if (res.data.ok) {
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
              this.getList()
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped>
.disabled /deep/ .el-upload--picture-card {
  display: none
}
</style>
