<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          style="margin-bottom:10px"
          @click="handleCreate"
      >添加</el-button>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="书屋名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrLibraryName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="书架名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrDescription }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="230px"
      >
        <template v-slot="{row}">
          <el-button type="success" size="mini" @click="visit(row)">查看</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>

    <el-dialog width="32%" :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-position="left"
          label-width="100px"
          style="margin-left:50px;"
          size="small"
      >
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="书屋名称" prop="StrLibraryID">
              <el-select v-model="formModel.StrLibraryID" style="width:100%" class="filter-item" placeholder="请选择" @change="swChange">
                <el-option v-for="item in swList" :key="item.StrID" :label="item.StrName" :value="item.StrID" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="书架名称" prop="StrName">
              <el-input v-model="formModel.StrName" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="书架描述">
              <el-input v-model="formModel.StrDescription" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right;">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'bookshelf',
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn:"1",
        Sort:"-1",
        OrderBy: "DTCreatedDate",
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加书架'
      },
      rules: {
        strSchoolId: [
          { required: true, message: '请选择组织', trigger: 'change' }
        ],
        StrLibraryID: [
          { required: true, message: '请选择书屋', trigger: 'change' }
        ],
        StrName: [
          { required: true, message: '请输入书架名称', trigger: 'blur' }
        ]
      },
      formModel: {},
      xxList: [],
      swList: []
    }
  },
  created() {
    this.getList()
    this.getLibraryList()
    // this.getXXList()
  },
  methods: {
    // 获取书架列表
    getList() {
      this.$axios({
        url: this.localpath + 'bookshelfs/search',
        method: 'post',
        data:JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })
    },
    // 获取书屋列表
    getLibraryList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.swList = res.data.msg
        }
      })
    },
    // 根据书屋名称查id
    swChange(val) {
      this.swList.forEach(item => {
        if (val === item.StrName) {
          this.formModel.StrLibraryID = item.StrID
        }
      })
    },
    // 确定添加或修改
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
              this.$axios({
                url: this.localpath + 'bookshelfs',
                method: 'put',
                data:JSON.stringify(this.formModel),
                //发送格式为json
                headers:
                    {
                      'Content-Type': 'application/json',
                      'dataType': 'json',
                      'Authorization': localStorage.getItem("token")
                    }
              }).then((res) => {
                if (res.data.ok) {
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  })
                  this.getList()
                  this.dialogFormVisible = false
                }else{
                  this.$message({
                    message: res.data.msg,
                    type: 'error'
                  })
                }
              })
            }
        })
      } else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'bookshelfs/'+this.formModel.StrID,
              method: 'patch',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
                this.getList()
                this.dialogFormVisible = false
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          }
        })
      }
    },
    // 添加
    handleCreate() {
      this.formModel = {}
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    // 编辑
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.$axios({
        url: this.localpath + 'bookshelfs/'+row.StrID,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.formModel = res.data.msg
        }else{
          this.$notify({
            message: res.data.msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    //点击查看按钮
    visit(row){
      localStorage.setItem("StrBookshelfID",row.StrID)
      this.$router.push("/bookfile")
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除该书架吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.localpath + 'bookshelfs/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.getList()
          }else{
            this.$notify({
              message: res.data.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

