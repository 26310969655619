<template>
  <div class="container">
    <div class="filter-container" >
        <el-button
            :style="{ display: add_btn_show }"
          class="filter-item"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handleCreate"
      >添加</el-button>
      <el-form :inline="true" class="form" size="small" :model="listQuery" v-if="$store.state.isAreaManager">
        <el-form-item class="marginBottom">
          <el-input v-model="listQuery.Search.StrName" size="mini" label="" placeholder="请输入组织" @keydown.enter="getList()" clearable/>
        </el-form-item>
        <el-form-item class="marginBottom">
          <el-button
              class="filter-item btnBottom"
              type="primary"
              icon="el-icon-search"
              size="mini"
              style="margin-left:4px"
              @click="getList()"
          >查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column show-overflow-tooltip label="ID" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrID }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="组织" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrName }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="所在省" align="center"  width="180">
        <template v-slot="{row}">
          <span>{{ row.StrProvince }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="所在市" align="center" width="180">
        <template v-slot="{row}">
          <span>{{ row.StrCity }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="所在区" align="center" width="180">
        <template v-slot="{row}">
          <span>{{ row.StrDistrict }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="创建时间" align="center" width="180">
        <template v-slot="{row}">
          <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="80"
          class-name="small-padding fixed-width"
          fixed="right"
      >
        <!-- $index -->
        <template v-slot="{row}">
          <el-button type="primary" size="mini" @click.stop="handleUpdate(row)">编辑</el-button>
<!--          <el-button-->
<!--              v-if="row.status!='deleted'"-->
<!--              size="mini"-->
<!--              type="danger"-->
<!--              @click="handleDelete(row, $index)"-->
<!--          >删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          v-show="total >= 5"
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <el-dialog :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false" width="34%">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-position="left"
          label-width="90px"
          style="margin-left:50px;"
          size="small"
      >
        <el-row :gutter="20">
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="组织名称" prop="StrName">
              <el-input v-model="formModel.StrName" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="所在省" prop="StrProvince">
              <el-input v-model="formModel.StrProvince" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="所在市" prop="StrCity">
              <el-input v-model="formModel.StrCity" />
            </el-form-item>
          </el-col>
          <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
            <el-form-item label="所在区" prop="StrDistrict">
              <el-input v-model="formModel.StrDistrict" />
            </el-form-item>
          </el-col>
<!--          <el-col :span="24">-->
<!--            <el-form-item label="组织图片">-->
<!--              <el-upload-->
<!--                  v-if="dialogFormVisible"-->
<!--                  ref="upload"-->
<!--                  class="avatar-uploader"-->
<!--                  :action="uploadUrl"-->
<!--                  list-type="picture-card"-->
<!--                  :on-success="handleSuccess"-->
<!--                  :on-remove="handleRemove"-->
<!--                  accept=".jpg,.jpeg,.png"-->
<!--                  :data="type"-->
<!--                  :file-list="fileList"-->
<!--              >-->
<!--                <i class="el-icon-plus" />-->
<!--              </el-upload>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'School',
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      dialogFormVisible: false,
      dialogStatus: '',
      add_btn_show: 'none',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        StrName: [
          { required: true, message: '请输入组织名称', trigger: 'blur' }
        ],
        StrProvince: [
          { required: true, message: '请输入组织', trigger: 'blur' }
        ],
        StrCity: [
          { required: true, message: '请输入组织名称', trigger: 'blur' }
        ],
        StrDistrict: [
          { required: true, message: '请输入组织名称', trigger: 'blur' }
        ]
      },
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      formModel: {
        // strProvince: '',
        // strCity: '',
        // strDistrict: ''
      },
      listQuery: {
        Page: 1,
        Perpage: 10,
        OrderBy: {"DTCreatedDate":-1},
        Search:{}
      },
      fileList: [],
      type: {
        strModule: 'school'
      },
      uploadUrl: '',
      query: this.$route.query.t
    }
  },
  watch: {
    // $route(from) {
    //   if (from.path === '/school') {
    //     this.listQuery.Search = ''
    //     this.getList()
    //     return
    //   }
    // }
  },
  created() {
     //刷新是否是区域管理员
    this.$store.commit('changeIsAreaManager');
    this.getList()
    //增加添加按钮
    // this.add_btn_show = (localStorage.getItem('IsAreaManager')||localStorage.getItem('StrID')==="System")?'':'none'
    // if(localStorage.getItem('IsAreaManager')||localStorage.getItem('StrID')==="System")
    //   this.add_btn_show = ''
    // console.log(this.add_btn_show)
    // console.log(localStorage.getItem('IsAreaManager'))
    // console.log(localStorage.getItem('StrID')==="System")
    if(localStorage.getItem('IsAreaManager')==='true'||localStorage.getItem('StrID')==='System'){
      this.add_btn_show = ''
    }

    // this.uploadUrl = process.env.VUE_APP_BASE_API + '/uploadFile/addFile'
  },
  methods: {
    // 获取组织列表
    getList() {
      this.$axios({
        url: this.localpath + 'organizations/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })

    },
    // 添加
    handleCreate() {
      this.formModel = {}
      this.fileList = []
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    // 确认添加或修改
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'organizations',
              method: 'put',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
              }else{
                this.$message({
                  message:res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      }
      else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'organizations/'+this.formModel.StrID,
              method: 'patch',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      }
    },
    // 编辑
    handleUpdate(row) {
      this.fileList = []
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
          this.formModel =row
          if (row.strPicUrl) {
           row.strPicUrl.split(',').forEach(item => {
              this.fileList.push({
                url: item
              })
            })
          }
    },
    handleDelete(row) {
      this.$confirm('确定要删除该类型吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$axios({
          url: this.localpath + 'organizations/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }else{
            this.$notify({
              message:res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    onSelected(data) {
      if (data.province.code && data.city.code && data.area.code) {
        this.formModel.strProvince = data.province.value
        this.formModel.strCity = data.city.value
        this.formModel.strDistrict = data.area.value
      } else {
        this.formModel.strProvince = ''
        this.formModel.strCity = ''
        this.formModel.strDistrict = ''
      }
    },
    handleSuccess(res, file, fileList) {
      const temp = []
      this.fileList = fileList
      console.log(fileList)
      // this.fileList.shift(this.fileList[0])
      this.fileList.forEach(item => {
        if (item.response && item.response.map && item.response.map.strUrl) {
          temp.push(item.response.map.strUrl)
        } else {
          temp.push(item.url)
        }
      })
      console.log(temp)
      this.formModel.strPicUrl = temp.join(',')
    },
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileList.splice(index, 1)
          this.formModel.strPicUrl = this.fileList.toString()
        }
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.form{
  display: flex;
  align-items: center;
  .marginTop{
    margin-top: 2px;
  }
  .marginBottom {
    margin-bottom: 0;
  }
  .btnBottom{
    margin-bottom:2px
  }
}
///deep/.distpicker-address-wrapper select{
//  height: 28px;
//  padding: 0px.75rem;
//  margin-right:7px;
//  font-size:14px
//}
.filter-container{
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
}
</style>
