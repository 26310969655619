<template>
  <div style="padding: 10px;
    background: #fff;
    overflow:auto;">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card class="box-card" shodow="always">
          <div class="clearfix">
            <span>读者查询</span>
          </div>
          <el-form :model="formDZCXModel" class="demo-form-inline" size="small" label-width="100px">
            <el-form-item label="查询依据">
              <el-select v-model="formDZCXModel.keyName" placeholder="请选择">
                <el-option
                    v-for="item in cxyjList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="请输入">
              <el-input v-model="formDZCXModel.keywords" @keydown.enter.prevent="inputKeywords" />
              <div style="color:red">[回车]</div>
            </el-form-item>
          </el-form>
        </el-card>
        <br>
        <el-card class="box-card" shodow="always">
          <div class="clearfix">
            <span>当前读者</span>
          </div>
          <el-form :model="formXJDZXXModel" class="demo-form-inline" size="small" label-width="100px">
            <el-form-item label="读者证号">
              <el-input v-model="formXJDZXXModel.StrReaderCardNumber" readonly />
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="formXJDZXXModel.StrName" readonly />
            </el-form-item>
            <el-form-item label="单元">
              <el-input v-model="formXJDZXXModel.StrUnitName" readonly />
            </el-form-item>
            <el-form-item label="类型">
              <el-input v-model="formXJDZXXModel.StrReaderTypeName" readonly />
            </el-form-item>
            <el-form-item label="已借">
              <el-input v-model="formXJDZXXModel.IntBookBorrowNum" readonly="true" />
            </el-form-item>
            <el-form-item label="可借">
              <el-input v-model="formXJDZXXModel.IntAvailableBookBorrowNum" readonly="true" />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card class="box-card">
          <div class="clearfix">
            <span>当前读者已借阅图书</span>
          </div>
          <el-table
              :data="xjtsList"
              border
              fit
              highlight-current-row
              style="width: 100%;"
              @cell-click="cellXJClick"
          >
            <el-table-column
                label="图书条码"
                prop="id"
                align="center"
            >
              <template v-slot="{row}">
                <span>{{ row.StrBookBarcode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="正题名" align="center">
              <template v-slot="{row}">
                <span>{{ row.StrPositiveTitle }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分类号" align="center">
              <template v-slot="{row}">
                <span>{{ row.StrBookTypeName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="借阅时间" align="center">
              <template v-slot="{row}">
                <span>{{ new Date(row.DTCreatedDate).toLocaleDateString()}}</span>
              </template>
            </el-table-column>
            <el-table-column label="应还时间" align="center">
              <template v-slot="{row}">
                <span>{{ new Date(row.DTReturnDate).toLocaleDateString() }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
                fixed="right"
            >
              <template v-slot="{row}">
                <el-button type="primary" size="mini" @click="handleContinue(row)">续借</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name:'Renew',
  data() {
    return {
      formDZCXModel: {
        keyName: '卡号'
      },
      formXJDZXXModel: {},
      xjtsList: [],
      cxyjList: [
        {
          name: '卡号'
        }
      ]
    }
  },
  created() {
  },
  methods: {
    // 读者借阅查询
    inputKeywords() {
      const param = {
        StrReaderCardNumber: this.formDZCXModel.keywords
      }
      // 读者借阅查询
      this.$axios({
        url: this.localpath + 'borrowrecords/readercard/' + this.formDZCXModel.keywords,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.xjtsList = res.data.msg
          for(var i in this.xjtsList){
            if(this.xjtsList[i].StrType === "Journal"){
              this.xjtsList[i].StrBookTypeName = this.xjtsList[i].StrISBN
            }
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      //获得读者信息
      this.$axios({
        url: this.localpath + 'readers',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.formXJDZXXModel = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 续借
    handleContinue(row) {
      this.$confirm('是否对图书执行续借操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param,url
        if(row.StrType == 'Book'){
          param = {
            StrBookBarcode: row.StrBookBarcode
          }
          url ='books/continueborrow'
        }else{
          param = {
            StrJournalBarcode: row.StrBookBarcode
          }
          url ='journals/continueborrow'
        }
        this.$axios({
          url: this.localpath + url,
          method: 'post',
          data: JSON.stringify(param),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.$message({
              type: 'success',
              message: '续借成功!'
            })
            this.inputKeywords()
          }else{
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      })
    }
  }
}
</script>
<style scoped>
</style>
