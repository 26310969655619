<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small" :model="listQuery">
          <el-form-item label="查询条件">
            <el-input v-model="listQuery.keywords" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item>
            <el-select v-model="listQuery.keyName" placeholder="请选择">
              <el-option label="读者类型" value="StrReaderTypeName"/>
              <el-option label="读者姓名" value="StrName"/>
              <el-option label="读者卡号" value="StrReaderCardNumber"/>
              <el-option label="读者部门" value="StrDepartmentName"/>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="单元">
            <el-select v-model="listQuery.unitID" placeholder="请选择">
              <el-option
                  v-for="item in bmData"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-radio v-model="listQuery.Status" label=-1>显示停借</el-radio>
            <el-radio v-model="listQuery.Status" label=1>显示挂失</el-radio>
          </el-form-item>
          <el-form-item label="登记时间">
            <el-date-picker
                v-model="listQuery.DTStartCreatedDate"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
            <el-date-picker
                v-model="listQuery.DTEndCreatedDate"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="toQuery()"
            >查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                @click="getAllList"
            >全部
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="success"
                size="mini"
                icon="el-icon-menu"
                @click="goPage(true)"
            >类型管理
            </el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-s-home"
                @click="goPage(false)"
            >单元管理</el-button>
          </el-form-item> -->
          <el-form-item>
            <el-button
                v-if="!downloadLoading"
                class="filter-item"
                type="info"
                size="mini"
                icon="el-icon-download"
                @click="handleDownload"
            >导出
            </el-button>
            <el-button
                v-if="downloadLoading"
                :loading="downloadLoading"
                class="filter-item"
                type="info"
                size="mini"
                icon="el-icon-download"
            >正在导出
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
            >读者添加
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col>

      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 表格开始 -->
      <el-col>
        <el-table
            v-loading="listLoading"
            :data="list"
            border
            fit
            highlight-current-row
            style="width: 100%;"
        >
          <el-table-column label="卡号" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrReaderCardNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工号" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrWorkerNumber }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="单元" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrUnitName }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="部门" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrDepartmentName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小组" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrGroupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrReaderTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template v-slot="{row}">
              <span>{{ row.Status === 0 ? '正常' : row.Status === 1 ? '停借' : '挂失' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登记日期" align="center">
            <template v-slot="{row}">
              <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              width="330"
              class-name="small-padding fixed-width"
              fixed="right"
          >
            <template v-slot="{row,$index}">
              <el-button v-show="row.Status === 0" style="width:90px;" type="primary" size="mini"
                         @click="btnGSorTJ(row,'gs')">读者证挂失
              </el-button>
              <el-button v-show="row.Status === -1" type="warning" size="mini"
                         style="width:90px;border:none; background:	#BA55D3" @click="btnQXGSorTJ(row,'挂失')">取消挂失
              </el-button>
              <el-button v-show="row.Status === 0" style="width:90px;" type="info" size="mini"
                         @click="btnGSorTJ(row,'tj')">读者证停借
              </el-button>
              <el-button v-show="row.Status === 1" type="primary" size="mini"
                         style="width:90px;border:none; background:	#FF8C00" @click="btnQXGSorTJ(row,'停借')">取消停借
              </el-button>
              <el-button type="primary" style="width:55px;" size="mini" @click="handleUpdate(row)">编辑</el-button>
              <el-button
                  style="width:55px;"
                  size="mini"
                  type="danger"
                  @click="handleDelete(row,$index)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 表格结束 -->
        <!-- 分页开始 -->
        <div style="margin-top:50px;text-align:right;">
          <el-pagination
              background
              :total="total"
              :current-page="listQuery.Page"
              :page-size="listQuery.Perpage"
              :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
              layout="prev,pager,next,jumper,sizes,total"
              @size-change="pageSizeChange"
              @current-change="currPageChange"
          />
        </div>
        <!-- 分页结束 -->
      </el-col>
    </el-row>

    <el-dialog :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          size="small"
          label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="头像">
              <el-upload
                  class="avatar-uploader"
                  :headers="picheaders"
                  :class="{disabled:uploadDisabled}"
                  action=""
                  list-type="picture-card"
                  :on-change="handleSuccess"
                  :on-remove="handleRemove"
                  accept=".jpg,.jpeg,.png"
                  :auto-upload="false"
                  :file-list="fileList">
                <i class="el-icon-plus"/>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <template v-if="dialogStatus==='create'">
              <el-form-item label="姓名" prop="StrName">
                <el-input v-model="formModel.StrName"/>
              </el-form-item>
              <el-form-item label="卡号" prop="StrReaderCardNumber">
                <el-input v-model="formModel.StrReaderCardNumber"/>
              </el-form-item>
            </template>
<!--            读者修改时禁止修改姓名和卡号-->
            <template v-else>
              <el-form-item label="姓名" prop="StrName">
                <el-input v-model="formModel.StrName" disabled/>
              </el-form-item>
              <el-form-item label="卡号" prop="StrReaderCardNumber">
                <el-input v-model="formModel.StrReaderCardNumber" disabled/>
              </el-form-item>
            </template>
            <el-form-item label="性别" prop="title">
              <el-radio v-model="formModel.StrSex" label="1">男</el-radio>
              <el-radio v-model="formModel.StrSex" label="0">女</el-radio>
            </el-form-item>
            <el-form-item label="工号" prop="title">
              <el-input v-model="formModel.StrWorkerNumber"/>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item label="所属单元" prop="StrUnitID">
              <el-select v-model="formModel.StrUnitID" class="filter-item" placeholder="请选择">
                <el-option v-for="item in bmData" :key="item.StrID" :label="item.StrName" :value="item.StrID" />
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <!-- <el-form-item :prop="isStudent ? 'StrDepartmentID' : 's'" label="部门"> -->
            <el-form-item prop="StrDepartmentID" label="部门">
              <el-select v-model="formModel.StrDepartmentID" placeholder="请选择"
                         @change="njChange(formModel.StrDepartmentID)">
                <el-option
                    v-for="item in njList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item :prop="isStudent ? 'StrGroupID' : 's'" label="小组"> -->
            <el-form-item prop="StrGroupID" label="小组" v-show="isShow">
              <el-select v-model="formModel.StrGroupID" placeholder="请选择" @change="bjChange">
                <el-option v-for="item in bjList" :key="item.StrID" :label="item.StrName" :value="item.StrID"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型" prop="StrReaderTypeID">
              <!--  :disabled="isTeacher || isStudent" -->
              <el-select v-model="formModel.StrReaderTypeName" class="filter-item" placeholder="请选择"
                         @change="getLXStrId">
                <el-option v-for="item in dzlxList" :key="item.StrID" :label="item.StrName" :value="item.StrName"/>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item prop="StrStudyPhase" label="学段">
              <el-select v-model="formModel.StrStudyPhase" placeholder="请选择">
                <el-option v-for="item in xdList" :key="item.strName" :label="item.strName" :value="item.strName" />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="联系方式">
              <el-input v-model="formModel.StrContact"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电子邮箱">
              <el-input v-model="formModel.StrEmailAddr"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="formModel.StrRemark" type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right">
        <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  data() {
    return {
      selectDepartmentIndex: 0,
      selectGroupIndex: 0,
      isShow: true,
      list: null,
      total: 0,
      listLoading: true,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加读者'
      },
      rules: {
        StrReaderCardNumber: [
          {required: true, message: '请输入卡号', trigger: 'blur'}
        ],
        StrName: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        StrReaderTypeID: [
          {required: true, message: '请选择类型', trigger: 'blur'}
        ],
        StrUnitID: [
          {required: true, message: '请选择单元', trigger: 'blur'}
        ],
        StrLibraryID: [
          {required: true, message: '请选择书屋', trigger: 'blur'}
        ],
        StrDepartmentID: [
          {required: true, message: '请选择部门', trigger: 'blur'}
        ],
        StrGroupID: [
          {required: true, message: '请选择小组', trigger: 'blur'}
        ],
        StrStudyPhase: [
          {required: true, message: '请选择学段', trigger: 'blur'}
        ]
      },
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      dialogDZLXVisible: false,
      dialogBMGLVisible: false,
      formModel: {
        AvatarChange: false,
        StrGroupID: '',
        StrGroupName: '',
        StrDepartmentID: '',
        StrDepartmentName: '',
      },
      Query: {},
      swList: [],
      dzlxList: [],
      bmData: [],
      props: {
        label: 'strNodeName'
      },
      cardList: [
        {
          name: '身份证'
        },
        {
          name: '其他证件'
        }
      ],
      uploadUrl: '',
      fileList: [],
      listQuery: {
        Page: 1,
        Perpage: 10,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {}
      },
      bjList: [],
      njList: [],
      Url: '',
      xdList: [
        {
          strName: '其他'
        },
        {
          strName: '小学'
        },
        {
          strName: '初中'
        },
        {
          strName: '高中'
        },
        {
          strName: '大学'
        },
        {
          strName: '硕士'
        },
        {
          strName: '博士'
        }
      ],
      isStudent: false,
      isTeacher: false
    }
  },
  computed: {
    uploadDisabled: function () {
      if (this.fileList.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    //根据小组id查询
    if (localStorage.getItem("StrGroupID") !== null && localStorage.getItem("StrGroupID") !== '') {
      this.listQuery.StrGroupID = localStorage.getItem("StrGroupID")
      localStorage.removeItem("StrGroupID")
    }
    if (localStorage.getItem("keyName")) {
      this.listQuery.keyName = localStorage.getItem("keyName")
      localStorage.removeItem("keyName")
    }
    if (localStorage.getItem("keywords")) {
      this.listQuery.keywords = localStorage.getItem("keywords")
      localStorage.removeItem("keywords")
    }
    // this.createGetList()
    this.getList()
    this.getDZLXList()
    this.getbmData()
    this.fetchReaderGradeList()
  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.listQuery.DTStartCreatedDate)
          const day2 = new Date(this.listQuery.DTEndCreatedDate)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.DTEndCreatedDate = ''
          }
      },
    //点击查询，清除多余条件
    toQuery() {
      this.listQuery.StrGroupID = null
      this.getList()
    },

    //查询图书
    getList() {
      this.listLoading = true
      const param = {
        Page: this.listQuery.Page,
        Perpage: this.listQuery.Perpage,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {}
      }
      if (this.listQuery.StrGroupID != "" && this.listQuery.StrGroupID != null) {
        param.Search["StrGroupID"] = this.listQuery.StrGroupID
      }
      if (this.listQuery.DTStartCreatedDate != "" && this.listQuery.DTStartCreatedDate != null) {
        param.Search["DTStartCreatedDate"] = this.listQuery.DTStartCreatedDate + 'T00:00:00.999Z'
      }
      if (this.listQuery.DTEndCreatedDate != "" && this.listQuery.DTEndCreatedDate != null) {
        param.Search["DTEndCreatedDate"] = this.listQuery.DTEndCreatedDate + 'T23:59:59.999Z'
      }
      // if(this.listQuery.unitID!=""){
      //   param.Search["StrUnitID"]=this.listQuery.unitID
      // }
      if (this.listQuery.keywords != "" && this.listQuery.keyName != null && this.listQuery.keyName != '' && this.listQuery.keywords != null) {
        param.Search[this.listQuery.keyName] = this.listQuery.keywords
      }
      if (this.listQuery.Status != 0) {
        param.Search["Status"] = this.listQuery.Status
      }
      this.$axios({
        url: this.localpath + 'readers/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })
    },
    // 获取单元
    getbmData() {
      const param = {
        OrderBy: {"DTCreatedDate": -1},
        Page: 1,
        Perpage: 10,
      }
      this.$axios({
        url: this.localpath + 'units/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.bmData = res.data.msg
        }
      })
    },
    // 获取读者类型
    getDZLXList() {
      const param = {
        OrderBy: {"DTCreatedDate": -1},
        Page: 1,
        Perpage: 10,
      }
      this.$axios({
        url: this.localpath + 'readertypes/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.dzlxList = res.data.msg
        }
      })
    },
    // 获取部门
    fetchReaderGradeList() {
      const param = {
        OrderBy: {"DTCreatedDate": -1},
        Page: 1,
        Perpage: 10,
      }
      this.$axios({
        url: this.localpath + 'departments/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.njList = res.data.msg
        }
      })
    },
    // 根据部门获取小组
    njChange(val) {

      const param = {
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {"StrDepartmentID": val},
        Page: 1,
        Perpage: 999,
      }
      this.$axios({
        url: this.localpath + 'groups/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.bjList = res.data.msg
          this.formModel.StrGroupID = ''
          this.isShow = false
          this.isShow = true
        }
      })
    },
    bjChange() {

    },
    // 获取全部
    getAllList() {
      this.listQuery = {
        Page: 1,
        Perpage: 10,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      },
          this.getList()
    },
    // 获取读者类型id
    getLXStrId(val) {
      this.dzlxList.forEach(item => {
        if (item.StrName === val) {
          this.formModel.StrReaderTypeID = item.StrID
        }
      })
      if (val === '学生') {
        this.isStudent = true
        this.isTeacher = false
      } else if (val === '教师') {
        this.isTeacher = true
        this.isStudent = false
      }
    },
    // 上传图片成功
    handleSuccess(file, fileList) {
      this.fileList = fileList
      this.formModel.AvatarChange = true
      //图片文件转base64
      this.convertImgToBase64(file.raw, base64Str => this.formModel.StrAvatar = base64Str, error => console.log(error));
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    //转base64
    convertImgToBase64(imageFile, callback, errorCallback) {
      try {
        let reader = new FileReader()
        reader.readAsDataURL(imageFile)
        reader.onload = function (e) {
          if (callback) {
            let base64Str = e.target.result
            callback(base64Str)
          }
        }
      } catch (error) {
        console.error(error)
        if (errorCallback) {
          errorCallback(error)
        }
      }
    },
    // 读者证挂失和停借
    btnGSorTJ(row, val) {
      if (val === 'gs') {
        this.$confirm(`是否对卡号${row.StrReaderCardNumber}读者证挂失`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.Status = -1
          this.$axios({
            url: this.localpath + 'readers/' + row.StrID,
            method: 'patch',
            data: JSON.stringify(row),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '挂失成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
        })
      } else if (val === 'tj') {
        this.$confirm(`是否对卡号${row.StrReaderCardNumber}读者证停借`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.Status = 1
          this.$axios({
            url: this.localpath + 'readers/' + row.StrID,
            method: 'patch',
            data: JSON.stringify(row),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '停借成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
        })
      }
    },
    // 取消挂失和停借
    btnQXGSorTJ(row, val) {
      this.$confirm(`是否对卡号${row.StrReaderCardNumber}读者证取消${val}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.Status = 0
        this.$axios({
          url: this.localpath + 'readers/' + row.StrID,
          method: 'patch',
          data: JSON.stringify(row),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$message({
              message: '取消成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      })
    },
    //跳转
    goPage(val) {
      if (val) {
        this.$router.push('/type')
      } else {
        this.$router.push('/unite')
      }
    },
    //添加或者编辑
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'readers',
              method: 'put',
              // url:this.localpath+'uploadtest',
              // method:'post',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      } else {
        for (var i = 0; i < this.njList.length; i++) {
          if (this.formModel.StrDepartmentID === this.njList[i].StrID) {
            this.formModel.StrDepartmentName = JSON.parse(JSON.stringify(this.njList[i].StrName));
            break;
          }
        }
          // eslint-disable-next-line no-redeclare
        for (var i = 0; i < this.bjList.length; i++) {
          if (this.formModel.StrGroupID === this.bjList[i].StrID) {
            this.formModel.StrGroupName = JSON.parse(JSON.stringify(this.bjList[i].StrName))
            break;
          }
        }
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'readers/' + this.formModel.StrID,
              method: 'patch',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      }
    },
    //弹出添加对话框
    handleCreate() {
      this.formModel = {
        StrSex: '1',
        AvatarChange: false
      }
      this.fileList = []
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.isStudent = false
      this.isTeacher = false
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    //弹出编辑对话框
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.fileList = []
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.formModel = row
      this.formModel.AvatarChange = false
      if (row.StrReaderTypeName === '教师') {
        this.isTeacher = true
        this.isStudent = false
      } else if (row.StrReaderTypeName === '学生') {
        this.isTeacher = false
        this.isStudent = true
      }
      this.bmData.forEach(item => {
        if (item.StrName === row.StrUnitName) {
          this.formModel.StrUnitID = item.StrID
        }
      })
      if (row.StrAvatarURL != "") {
        this.fileList.push({url: this.localpath + 'avatars/' + row.StrAvatarURL})
      } else {
        this.fileList.push({url: this.localpath + 'avatars/default.png'})
      }
      this.njChange(row.StrDepartmentID)
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定删除该读者吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$axios({
          url: this.localpath + 'readers/' + row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              message: res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      const param = {
        Page: this.listQuery.Page,
        Perpage: this.listQuery.Perpage,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {}
      }
      if (this.listQuery.DTStartCreatedDate != "" && this.listQuery.DTStartCreatedDate != null) {
        param.Search["DTStartCreatedDate"] = this.listQuery.DTStartCreatedDate
      }
      if (this.listQuery.DTEndCreatedDate != "" && this.listQuery.DTEndCreatedDate != null) {
        param.Search["DTEndCreatedDate"] = this.listQuery.DTEndCreatedDate
      }
      if (this.listQuery.unitID != "") {
        param.Search["StrUnitID"] = this.listQuery.unitID
      }
      if (this.listQuery.keywords != "" && this.listQuery.keyName != null) {
        param.Search[this.listQuery.keyName] = this.listQuery.keywords
      }
      if (this.listQuery.Status != 0) {
        param.Search["Status"] = this.listQuery.Status
      }
      this.$axios({
        url: this.localpath + 'readers/export',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(param),
        responseType: 'blob',
        headers:
            {
              'Content-Type': 'application/json',
              dataType: 'json',
              Authorization: localStorage.getItem("token")
            }
      }).then(res => {
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //处理文档流
        var data = new Date()
        let timem = data.getTime();
        const fileName = "读者档案" + timem + ".xlsx";
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(() => {

        this.downloadLoading = false
      })
    }
  }
}
</script>
<style scoped>
.el-button + .el-button {
  margin-left: 4px;
}

.disabled /deep/ .el-upload--picture-card {
  display: none
}

.el-radio {
  margin-right: 10px;
}
</style>
