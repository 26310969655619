<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-date-picker
                v-model="listQuery.Search.DTStartTime"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD HH:mm:ss"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.Search.DTEndTime"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD HH:mm:ss"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="组织名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrOrganizationName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="馆藏数量" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedBookCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="期刊数量" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedJournalCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="读者数量" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedReaderCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书借阅次数" align="center">
        <template v-slot="{row}">
          <span>{{ row.CreatedBookBorrowedCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="生均藏书" align="center">
        <template v-slot="{row}">
          <span>{{ row.AvgHave}}</span>
        </template>
      </el-table-column>
      <el-table-column label="生均借阅" align="center">
        <template v-slot="{row}">
          <span>{{ row.AvgBorrow }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name:'JournalLendRanking',
  data() {
    return {
      list: [

      ],
      listLoading: false,
      listQuery: {
        Page: 1,
        Perpage:10,
        Search:{
        }
      },
      total: 0,
      downloadLoading: false,
      swList: [],
      Url: ''
    }
  },
  created() {
    this.getschoolList()
    this.getList()
  },
  methods: {
    // 查询
    getList() {
      //需要使用时间日期选择器，才能选择时间
      this.listLoading = true
      var end = this.listQuery.Search.DTEndTime
      if (end!==undefined&&end!=null) {
        this.listQuery.Search.DTEndTime.setHours(23,59,59,999)
      }
      this.$axios({
        url: this.localpath + 'area/orgscount',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg

          for(var i=0;i<this.list.length;i++){
            if(this.list[i].CreatedReaderCount !== 0 ){
              if(this.list[i].CreatedBookCount/this.list[i].CreatedReaderCount >= 0.05) {
                this.list[i].AvgHave = (this.list[i].CreatedBookCount / this.list[i].CreatedReaderCount).toFixed(1)
              }else{
                this.list[i].AvgHave=0;
              }
              if(this.list[i].CreatedBookBorrowedCount/this.list[i].CreatedReaderCount>=0.05){
                this.list[i].AvgBorrow = (this.list[i].CreatedBookBorrowedCount/this.list[i].CreatedReaderCount).toFixed(1)
              }else{
                this.list[i].AvgBorrow=0;
              }
            }else{
              this.list[i].AvgHave = 0;
              this.list[i].AvgBorrow = 0;
            }
          }
          this.listLoading = false
        }
      })
    },
    //重置
    reset(){
      this.listQuery={
        Page: 1,
        Perpage: 10,
        Search:{}
      }
      this.getList();
    },
    // 获取书屋
    getschoolList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.swList = res.data.msg
        }
      })
    },


    // 分页
    // pageSizeChange(Perpage) {
    //   this.listQuery.Perpage = Perpage
    //   this.getList()
    // },
    // currPageChange(Page) {
    //   this.listQuery.Page = Page
    //   this.getList()
    // }
  }
}
</script>

