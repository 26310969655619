<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small">
          <el-form-item label="组织" v-if="$store.state.isAreaManager">
            <el-select v-model="listQuery.StrOrganizationID" placeholder="请选择" @change="getSWList">
              <el-option
                  v-for="item in OrganizationList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="书屋">
            <el-select v-model="listQuery.StrLibraryID" placeholder="请选择">
              <el-option
                  v-for="item in swList"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.DTStartTime"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="listQuery.DTEndTime"
                type="date"
                placeholder="结束时间"
                :disabled-date="disabledDate"
                @change = "choose"
                value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click.prevent="getList"
            >查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="reset"
            >重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="借阅次数" align="center">
        <template v-slot="{row}">
          <span>{{ row.BorrowCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="正题名" align="center">
        <template v-slot="{row}">
          <span>{{ row.Book.StrPositiveTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作者" align="center">
        <template v-slot="{row}">
          <span>{{ row.Book.StrFirstResponsible }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="图书条码" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.Book.StrBookBarcode }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="ISBN" align="center">
        <template v-slot="{row}">
          <span>{{ row.Book.StrISBN }}</span>
        </template>
      </el-table-column>
      <el-table-column label="每套价格" align="center">
        <template v-slot="{row}">
          <span>{{ row.Book.FloatPricePer }}</span>
        </template>
      </el-table-column>
      <el-table-column label="书屋位置" align="center">
        <template v-slot="{row}">
          <span>{{ row.Book.StrLibraryName }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name:'BookLendRanking',
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        Page: 1,
        Perpage: 50,
      },
      libraryQuery: {
        Page: 1,
        Perpage: 999,
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        Search:{}
      },
      total: 0,
      downloadLoading: false,
      swList: [],
      OrganizationList:[],
      Url: ''
    }
  },
  created() {
    //刷新是否是区域管理员
    this.$store.commit('changeIsAreaManager');
    if (!this.$store.state.isAreaManager){ this.getSWList()}else { this.getorglist()}
    this.getList()

  },
  methods: {
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.listQuery.DTStartTime)
          const day2 = new Date(this.listQuery.DTEndTime)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.DTEndTime = ''
          }
      },
// 查询
    getList() {
        // eslint-disable-next-line no-unused-vars
      const parem ={
          Page: 1,
          Perpage: 50,
          DTEndTime:'',
          DTStartTime:''
      }
      this.listLoading = true
      var end = this.listQuery.DTEndTime
      if (end!==undefined&&end!=null) {
         parem.DTStartTime = this.listQuery.DTStartTime+"T00:00:00.999Z"
          parem.DTEndTime = this.listQuery.DTEndTime+"T23:59:59.999Z"
      }

      this.$axios({
        url: this.localpath + 'organizations/topbookswithdetails',
        method: 'post',
        data: JSON.stringify(parem),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.listLoading = false
        }
      })
    },
    //重置
    reset(){
      this.listQuery={
        Page: 1,
        Perpage: 50,
      }
      this.libraryQuery={
        Page: 1,
        Perpage: 999,
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        Search:{}
      }
      this.getList();
    },
    // 获取书屋
    getSWList() {
      if (!this.$store.state.isAreaManager){
        //普通管理员
        this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.swList = res.data.msg
        }
      })
      }else{
        //区域管理员
        this.libraryQuery.Search.StrOrganizationID=this.listQuery.StrOrganizationID
        this.$axios({
          url: this.localpath + 'librarys/search',
          method: 'post',
          data: JSON.stringify(this.libraryQuery),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.swList = res.data.msg
          }
        })
      }

    },
    //获取学校
    getorglist(){
      this.$axios({
        url: this.localpath + 'organizations/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.OrganizationList = res.data.msg
        }
      })
    }
    // 分页
    // pageSizeChange(Perpage) {
    //   this.listQuery.Perpage = Perpage
    //   this.getList()
    // },
    // currPageChange(Page) {
    //   this.listQuery.Page = Page
    //   this.getList()
    // }
  }
}
</script>

