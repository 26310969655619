<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleCreate"
      >添加</el-button>
    </div>

    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;overflow: auto"
    >
      <el-table-column label="部门名称" align="center" width="150">
        <template v-slot="{row}">
          <span>{{ row.StrDepartmentName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="小组名称" align="center"  width="150">
        <template v-slot="{row}">
          <span>{{ row.StrName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="小组描述" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrDescription }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="350"
      >
        <template v-slot="{row,$index}">
          <el-button type="info" size="mini" @click="stopBorrow(row)">停借该组</el-button>
          <el-button type="success" size="mini" @click="findReader(row)">查看读者</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
          <el-button
              v-if="row.status!='deleted'"
              size="mini"
              type="danger"
              @click="handleDelete(row,$index)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.pageIndex"
          :page-size="listQuery.pageSize"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>

    <el-dialog width="32%" :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-position="left"
          label-width="130px"
          size="small">
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="部门名称" prop="StrDepartmentID">
              <el-select v-model="formModel.StrDepartmentID" placeholder="请选择">
                <el-option
                    v-for="item in njList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="小组名称" prop="StrName">
              <el-input v-model="formModel.StrName" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="小组描述">
              <el-input v-model="formModel.StrDescription" type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn:"1",
        Sort:"-1",
        OrderBy: "DTCreatedDate",
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        StrName: [
          { required: true, message: '请输入小组名称', trigger: 'blur' }
        ],
        StrDepartmentID: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ]
      },
      // 小组、部门
      bjnjList: [
        {
          val: 'strName',
          label: '小组'
        },
        {
          val: 'strGradeId',
          label: '部门'
        }
      ],
      // 排序
      sortList: [
        {
          id: 'desc',
          label: '降序'
        },
        {
          id: 'asc',
          label: '升序'
        }
      ],
      formModel: {},
      formSortModel: {},
      njList: []
    }
  },
  created() {
    this.getList()
    // this.fetchNJList()
  },
  methods: {
    getList() {
      this.listLoading = true
      this.$axios({
        url: this.localpath + 'groups/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })
    },
    //停借改组
    stopBorrow(row){
      this.$confirm('确定要'+row.StrName+'停借吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.localpath + 'readers/bannedByGroup',
          method: 'post',
          data: JSON.stringify({id: row.StrID}),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.$message({
              message: '停借成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消停借'
        })
      })
    },
    findReader(row){
      localStorage.setItem("StrGroupID",row.StrID)
      this.$router.push("/readerfile")
    },
      // 获取部门
      fetchNJList() {
      const param={
        OrderBy: {"DTCreatedDate":-1},
      }
      this.$axios({
          url: this.localpath + 'departments/search',
          method: 'post',
          data:JSON.stringify(param),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(response => {
          if (response.data.ok) {
            console.log(response.data.msg);
            this.njList = response.data.msg
          }
        })
      },
    //添加或者修改
      btnAddorMod() {
        if (this.dialogStatus === 'create') {
          this.$refs['dataForm'].validate(valid => {
            if (valid) {
              this.$axios({
                url: this.localpath + 'groups',
                method: 'put',
                data:JSON.stringify(this.formModel),
                //发送格式为json
                headers:
                    {
                      'Content-Type': 'application/json',
                      'dataType': 'json',
                      'Authorization': localStorage.getItem("token")
                    }
              }).then((res) => {
                if (res.data.ok) {
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  })
                }else{
                  this.$message({
                    message:res.data.msg,
                    type: 'error'
                  })
                }
                this.getList()
              })
              this.dialogFormVisible = false
            }
          })
        }
        else {
          this.$refs['dataForm'].validate(valid => {
            if (valid) {
              this.$axios({
                url: this.localpath + 'groups/'+this.formModel.StrID,
                method: 'patch',
                data:JSON.stringify(this.formModel),
                //发送格式为json
                headers:
                    {
                      'Content-Type': 'application/json',
                      'dataType': 'json',
                      'Authorization': localStorage.getItem("token")
                    }
              }).then((res) => {
                if (res.data.ok) {
                  this.$message({
                    message: '编辑成功',
                    type: 'success'
                  })
                }else{
                  this.$message({
                    message: res.data.msg,
                    type: 'error'
                  })
                }
                this.getList()
              })
              this.dialogFormVisible = false
            }
          })
        }
      },
      handleCreate() {
        this.formModel = {}
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
        this.fetchNJList()
      },
      handleUpdate(row) {
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
            this.formModel = row
      },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除该小组吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.localpath + 'groups/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }else{
            this.$notify({
              message:res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

<style scoped>
/*.el-form {*/
/*  margin: 0;*/
/*  padding: 3px;*/
/*}*/
.filter-container{
  padding-bottom: 10px;
}
</style>
