<template>
  <div class="container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="借阅" name="first">
        <el-row type="flex" align="middle" justify="space-between">
          <el-col :span="6">
            <el-form :model="formReaderModel" class="demo-form-inline" label-width="100px">
              <el-form-item label="读者证号">
                <el-input v-model="formReaderModel.StrReaderCardNumber" readonly="true" />
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="formReaderModel.StrName" readonly="true" />
              </el-form-item>
              <el-form-item label="单元">
                <el-input v-model="formReaderModel.StrUnitName" readonly="true" />
              </el-form-item>
              <el-form-item label="类型">
                <el-input v-model="formReaderModel.StrReaderType" readonly="true" />
              </el-form-item>
              <el-form-item label="已借">
                <el-input v-model="formReaderModel.IntBookBorrowNum" readonly="true" />
              </el-form-item>
              <el-form-item label="可借">
                <el-input v-model="formReaderModel.intCouldBorrow" readonly="true" />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="18">
            <div style="padding:50px 0;text-align:center">
              <el-form :inline="true" :model="formJYModel" class="demo-form-inline" size="medium">
                <el-form-item>
                  <div style="display:flex;">
                    <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:200px;font-size: 17px;">①请输入读者卡号</div>
                    <el-input v-model="formJYModel.StrReaderCardNumber" @keyup.enter="submit('dz')" />
                  </div>
                  <div>按Enter(回车键)确定</div>
                </el-form-item>
                <el-form-item>
                  <div style="display:flex;">
                    <div style="color:#46c169;font-weight:bold;flex-grow:0;flex-shrink:0;text-align:right;padding-right:10px;width:200px;font-size: 17px;">②请输入期刊条码</div>
                    <el-input v-model="formJYModel.strJournalBarcode" :disabled="isShow" @keyup.enter="submit('qk')" />
                  </div>
                  <div>按Enter(回车键)确定</div>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <el-card class="box-card">
          <div class="clearfix">
            <span>当前借阅信息</span>
          </div>
          <el-table
              border
              fit
              highlight-current-row
              style="width: 100%;"
              :data="jyxxList"
          >
            <el-table-column
                label="分类号"
                prop="id"
                align="center"
            >
              <template v-slot="{row}">
                <span>{{ row.strClassificationNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="编号(期刊条码)" align="center">
              <template v-slot="{row}">
                <span>{{ row.strJournalBarcode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="期刊名" align="center">
              <template v-slot="{row}">
                <span>{{ row.strJournalTitle }}</span>
              </template>
            </el-table-column>
            <el-table-column label="期号" align="center">
              <template v-slot="{row}">
                <span>{{ row.strIssue }}</span>
              </template>
            </el-table-column>
            <el-table-column label="应还时间" align="center">
              <template v-slot="{row}">
                <span>{{ row.dtReturnDate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="超期" align="center">
              <template v-slot="{row}">
                <span>{{ parseInt(row.strDays) > 0 ? row.strDays : '未超期' }}</span>
                <!-- <span>{{ parseInt(row.strDays) <= 0 ? '未超期' : row.strDays }}</span> -->
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="归还" name="second">
        <el-row type="flex" align="middle" justify="space-between">
          <el-col :span="6">
            <el-form :model="formGHXXModel" class="demo-form-inline" label-width="100px">
              <el-form-item label="读者证号">
                <el-input v-model="formGHXXModel.strCardNumber" disabled />
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="formGHXXModel.strName" disabled />
              </el-form-item>
              <el-form-item label="单元">
                <el-input v-model="formGHXXModel.strUnitName" disabled />
              </el-form-item>
              <el-form-item label="类型">
                <el-input v-model="formGHXXModel.strReaderType" disabled />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form :inline="true" :model="formGHModel" class="demo-form-inline" size="medium">
              <el-form-item label="请输入期刊条码">
                <el-input v-model="formGHModel.strJournalBarcode" @keyup.enter="submit('gh')" />
                <div style="color:red">按Enter(回车键)确定</div>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-tabs v-model="activeNames">
          <el-tab-pane label="当前还书信息" name="four">
            <el-table
                border
                fit
                highlight-current-row
                style="width: 100%;"
                :data="dqhsList"
            >
              <el-table-column
                  label="分类号"
                  prop="id"
                  align="center"
              >
                <template v-slot="{row}">
                  <span>{{ row.strClassificationNumber }}</span>
                </template>
              </el-table-column>
              <el-table-column label="编号(期刊条码)" align="center">
                <template v-slot="{row}">
                  <span>{{ row.strJournalBarcode }}</span>
                </template>
              </el-table-column>
              <el-table-column label="期刊名" align="center">
                <template v-slot="{row}">
                  <span>{{ row.strJournalTitle }}</span>
                </template>
              </el-table-column>
              <el-table-column label="期号" align="center">
                <template v-slot="{row}">
                  <span>{{ row.strIssue }}</span>
                </template>
              </el-table-column>
              <el-table-column label="应还时间" align="center">
                <template v-slot="{row}">
                  <span>{{ row.dtReturnDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="超期" align="center">
                <template v-slot="{row}">
                  <span>{{ parseInt(row.strDays) > 0 ? row.strDays : '未超期' }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="当前读者借阅信息" name="five">
            <el-table
                border
                fit
                highlight-current-row
                style="width: 100%;"
                :data="dqdzList">
              <el-table-column
                  label="分类号"
                  prop="id"
                  align="center"
                  width="150"
              >
                <template v-slot="{row}">
                  <span>{{ row.strClassificationNumber }}</span>
                </template>
              </el-table-column>
              <el-table-column label="编号(期刊条码)" align="center">
                <template v-slot="{row}">
                  <span>{{ row.strJournalBarcode }}</span>
                </template>
              </el-table-column>
              <el-table-column label="期刊名" align="center">
                <template v-slot="{row}">
                  <span>{{ row.strJournalTitle }}</span>
                </template>
              </el-table-column>
              <el-table-column label="期号" align="center">
                <template v-slot="{row}">
                  <span>{{ row.strIssue }}</span>
                </template>
              </el-table-column>
              <el-table-column label="应还时间" align="center">
                <template v-slot="{row}">
                  <span>{{ row.dtReturnDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="超期" align="center">
                <template v-slot="{row}">
                  <span>{{ parseInt(row.strDays) > 0 ? row.strDays : '未超期' }}</span>
                  <!-- <span>{{ parseInt(row.strDays) <= 0 ? '未超期' : row.strDays }}</span> -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        v-model="dialogVisible"
        width="30%">
      <div style="font-size:50px;text-align:center;color:red">{{ message }}</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'JournalCirculation',
  data() {
    return {
      activeName: 'first',
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      bmTreeData: [],
      activeNames: 'four',
      isShow: true,
      formJYModel: {},
      formReaderModel: {},
      jyxxList: [],
      formGHModel: {},
      props: {
        label: 'strNodeName'
      },
      formGHXXModel: {},
      dqdzList: [],
      dqhsList: [],
      total: 0,
      listQuery: {
        pageIndex: 1,
        pageSize: 10,
        strUnitId: '',
        keywords: '',
        keyName: '',
        dtStartRecordDate: '',
        dtEndRecordDate: ''
      },
      list: [],
      listLoading: false,
      Url: '',
      dialogVisible: false,
      message: ''
    }
  },
  created() {
    // this.getBMList()
    // this.Url = process.env.VUE_APP_BASE_API
  },
  methods: {
    // 查询
    // getList() {
    //   this.listLoading = true
    //   fetchJournalList(this.listQuery).then(response => {
    //     if (response.code === 0) {
    //       this.listLoading = false
    //       this.list = response.map.list
    //       this.total = response.map.total
    //     }
    //   })
    //   // this.listQuery = {}
    // },
    // 获取单元
    // getBMList() {
    //   const param = {
    //     pageIndex: 0
    //   }
    //   fetchDZBMList(param).then(res => {
    //     if (res.code === 0) {
    //       this.bmTreeData.push(res.map.bmTreeData)
    //     }
    //   })
    // },
    // 当前借阅信息
    // getReader(val) {
    //   const param = {
    //     strCardNumber: this.formJYModel.strReaderCardNumber
    //   }
    //   btnQKLTDZInfo(param).then(res => {
    //     if (res.code === 0) {
    //       if (val === 'dz') {
    //         this.isShow = false
    //         this.formReaderModel = res.map.reader
    //         this.formReaderModel.intBorrow = res.map.intBorrow
    //         this.formReaderModel.intNoBorrow = res.map.intNoBorrow
    //         this.jyxxList = res.map.borrowJournalList
    //       } else {
    //         this.jyxxList = res.map.borrowJournalList
    //       }
    //     }
    //   })
    // },
    submit(val) {
      if (val === 'dz') {
        const param={
          StrReaderCardNumber:this.formJYModel.StrReaderCardNumber
        }
        this.$axios({
          url: this.localpath + 'readers',
          method: 'post',
          data:JSON.stringify(param),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then(res => {
          if (res.data.ok) {
            this.isShow = false
            this.formReaderModel = res.data.msg
            this.formReaderModel.intCouldBorrow=res.data.msg.int

            this.jyxxList = res.map.borrowJournalList
          }else{
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
        this.getReader('dz')
      }
      // else if (val === 'qk') {
      //   const param = {
      //     strJournalBarcode: this.formJYModel.strJournalBarcode,
      //     strReaderCardNumber: this.formJYModel.strReaderCardNumber
      //   }
      //   if (!param.strJournalBarcode) {
      //     this.$notify({
      //       title: '消息',
      //       message: '请输入图书条码',
      //       type: 'info'
      //     })
      //     return
      //   }
      //   btnQKJY(param).then(res => {
      //     if (res.code === 0) {
      //       this.dialogVisible = true
      //       this.message = '期刊借阅成功'
      //       this.getReader('ts')
      //     }
      //   })
      //   // this.formJYModel = {}
      // } else if (val === 'gh') {
      //   const param = {
      //     strJournalBarcode: this.formGHModel.strJournalBarcode
      //   }
      //   btnQKGH(param).then(res => {
      //     if (res.code === 0) {
      //       this.dialogVisible = true
      //       this.message = '期刊归还成功'
      //       this.formGHXXModel = res.map.reader
      //       this.dqdzList = res.map.borrowJournalList
      //       this.dqhsList = res.map.borrowJournalRecord
      //     }
      //   })
      //   this.formGHModel = {}
      // }
    },
    // nodeClick(row) {
    //   if (row.strId) {
    //     const param = {
    //       strUnitId: row.strId
    //     }
    //     this.listLoading = true
    //     fetchJournalList(param).then(res => {
    //       if (res.code === 0) {
    //         this.list = res.map.list
    //         this.listLoading = false
    //       }
    //     })
    //   }
    // },
    // 重置
    reset() {
      this.listQuery = {
        strUnitId: '',
        keywords: '',
        keyName: '',
        dtStartRecordDate: '',
        dtEndRecordDate: ''
      }
      this.getList()
    },
    // 导出
    // handleDownload() {
    //   this.downloadLoading = true
    //   const param = {
    //     isExp: 1
    //   }
    //   fetchJournalList(param).then(response => {
    //     if (response.code === 0) {
    //       this.downloadLoading = false
    //       const aLink = document.createElement('a')
    //       const url = this.Url + '/' + response.map.path + response.map.fileName
    //       aLink.href = url
    //       aLink.click()
    //     }
    //   })
    // },
    // 分页
    pageSizeChange(pageSize) {
      this.listQuery.pageSize = pageSize
      this.getList()
    },
    currPageChange(pageIndex) {
      this.listQuery.pageIndex = pageIndex
      this.getList()
    }
  }
}
</script>
<style scoped>
</style>
