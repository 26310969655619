<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card class="box-card" shodow="always">
          <div class="clearfix">
            <span>读者查询</span>
          </div>
          <el-form :model="formDZCXModel" class="demo-form-inline" size="small" label-width="100px">
            <el-form-item label="查询依据">
              <el-select v-model="formDZCXModel.keyName" placeholder="请选择">
                <el-option
                    v-for="item in cxyjList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="请输入">
              <el-input v-model="formDZCXModel.keywords" @keyup.enter="inputKeywords" />
              <div style="color:red">[回车]</div>
            </el-form-item>
          </el-form>
        </el-card>
        <br>
        <el-card class="box-card" shodow="always">
          <div class="clearfix">
            <span>当前读者</span>
          </div>
          <el-form :model="formXJDZXXModel" class="demo-form-inline" size="small" label-width="100px">
            <el-form-item label="读者证号">
              <el-input v-model="formXJDZXXModel.StrReaderCardNumber" readonly />
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="formXJDZXXModel.StrName" readonly />
            </el-form-item>
            <el-form-item label="单元">
              <el-input v-model="formXJDZXXModel.StrUnitName" readonly />
            </el-form-item>
            <el-form-item label="读者类型">
              <el-input v-model="formXJDZXXModel.StrReaderTypeName" readonly />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card class="box-card">
          <div class="clearfix">
            <span>当前读者已借阅图书</span>
          </div>
          <br>
          <el-table
              :data="xjtsList"
              border
              fit
              highlight-current-row
              style="width: 100%;"
          >
            <el-table-column
                label="图书条码"
                prop="id"
                align="center"
                width="100px"
            >
              <template v-slot="{row}">
                <span>{{ row.StrBookBarcode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="正题名" align="center">
              <template v-slot="{row}">
                <span>{{ row.StrPositiveTitle }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分类名" align="center">
              <template v-slot="{row}">
                <span>{{ row.StrBookTypeName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="借阅时间" align="center">
              <template v-slot="{row}">
                <span>{{ new Date(row.DTCreatedDate).toLocaleString() }}</span>
              </template>
            </el-table-column>
            <el-table-column label="应还时间" align="center">
              <template v-slot="{row}">
                <span>{{ new Date(row.DTReturnDate).toLocaleString() }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                width="145px"
                class-name="small-padding fixed-width"
                fixed="right"
            >
              <template v-slot="{row}">
                <el-button size="mini" type="primary" @click="handBookLoss(row,'丢失')">丢失</el-button>
                <el-button size="mini" type="primary" @click="handBookLoss(row,'损毁')">损毁</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name:'LostAndBreakRegister',
  data() {
    return {
      formDZCXModel: {
        keyName: '卡号'
      },
      formXJDZXXModel: {},
      xjtsList: [],
      cxyjList: [
        {
          name: '卡号'
        }
      ],
      formYCDZXXModel: {}
    }
  },
  created() {
  },
  methods: {
    inputKeywords() {
      const param = {
        StrReaderCardNumber: this.formDZCXModel.keywords
      }
      //获得读者信息
      this.$axios({
        url: this.localpath + 'readers',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.formXJDZXXModel = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      //获得读者借阅记录
      this.$axios({
        url: this.localpath + 'borrowrecords/readercard/' + this.formDZCXModel.keywords,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.xjtsList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    handBookLoss(row, val) {
      if (val === '丢失') {
        this.$confirm('是否对图书执行丢失操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const param={
            BookStatus:"IsLost",
          }
          this.$axios({
            url: this.localpath + 'books/' + row.StrBookBarcode+'/status',
            method: 'patch',
            data:JSON.stringify(param),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then(res => {
            if (res.data.ok) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.inputKeywords()
            }else {
              this.$message({
                type: 'error',
                message: res.data.msg,
              })
            }
          })
        })
      } else if (val === '损毁') {
        this.$confirm('是否对图书执行损毁操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const param={
            BookStatus:"IsBroken",
          }
          this.$axios({
            url: this.localpath + 'books/' + row.StrBookBarcode+'/status',
            method: 'patch',
            data:JSON.stringify(param),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then(res => {
            if (res.data.ok) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.inputKeywords()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg,
              })
            }
          })
        })
      }
    }
  }
}
</script>
<style scoped>
</style>
