<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          style="margin-bottom:10px"
          @click="handleCreate"
      >添加</el-button>
    </div>

    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="类型名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrName }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="分类号代码" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.strClassificationCode }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="可借天数" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <span>{{ row.IntAvailableDays }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="出版周期" align="center">
        <template v-slot="{row}">
          <span>{{ row.IntPublishCycle }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
      >
        <template v-slot="{row,$index}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row,$index)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>

    <el-dialog width="32%" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-width="120px"
          size="small"
      >
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="类型名称" prop="StrName">
              <el-input v-model="formModel.StrName" />
            </el-form-item>
          </el-col>
<!--          <el-col>-->
<!--            <el-form-item label="分类号代码" prop="StrClassificationCode">-->
<!--              <el-input v-model="formModel.StrClassificationCode" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col>-->
<!--            <el-form-item label="可借天数" prop="IntAvailableDays">-->
<!--              <el-input v-model="formModel.IntAvailableDays" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col>
            <el-form-item label="出版周期(天)" prop="IntPublishCycle">
              <el-input v-model="formModel.IntPublishCycle" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right;">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
// import {
//   fetchQKLXList,
//   btnQKLXDetail,
//   btnQKLXAdd,
//   btnQKLXDel,
//   btnQKLXMod
// } from '@/api/qikan'
export default {
  name:'journaltype',
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn:"1",
        Sort:"-1",
        OrderBy: "DTCreatedDate"
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        StrName: [
          { required: true, message: '请输入类型名称', trigger: 'blur' }
        ],
        // IntAvailableDays: [
        //   { required: true, message: '请输入可借天数', trigger: 'blur' }
        // ],
        IntPublishCycle: [
          { required: true, message: '请输入出版周期', trigger: 'blur' }
        ]
      },
      formModel: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取期刊类型列表
    getList() {
      this.$axios({
        url: this.localpath + 'journaltypes/search',
        method: 'post',
        data:JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok){
          this.list = res.data.msg
          this.total=res.data.total
          this.listLoading=false;
        }else{
          this.$message({
            message:res.data.msg,
            type: 'error'
          })
          this.listLoading = false
        }
      })
    },
    // 确认添加或修改
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            // this.formModel.IntAvailableDays=parseInt(this.formModel.IntAvailableDays)
            this.formModel.IntPublishCycle=parseInt(this.formModel.IntPublishCycle)
            this.$axios({
              url: this.localpath + 'journaltypes',
              method: 'put',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
                this.dialogFormVisible = false
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                  duration: 3000
                })
              }
              this.getList()
            })
          }
        })
      }
      else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            // this.formModel.IntAvailableDays=parseInt(this.formModel.IntAvailableDays)
            this.formModel.IntPublishCycle=parseInt(this.formModel.IntPublishCycle)
            this.$axios({
              url: this.localpath + 'journaltypes/'+this.formModel.StrID,
              method: 'patch',
              data:JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
                this.dialogFormVisible = false
              }else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
          }
        })
      }
    },
    // 添加
    handleCreate() {
      this.formModel = {}
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    // 编辑
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.$axios({
        url: this.localpath + 'journaltypes/'+row.StrID,
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.formModel = res.data.msg
        }
      })
    },
    // 删除
    handleDelete(row) {
      this.$confirm('您确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$axios({
          url: this.localpath + 'journaltypes/'+row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.getList()
          }else{
            this.$notify({
              message: res.data.msg,
              type: 'error',
              duration: 2000
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>

