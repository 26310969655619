<template>
  <el-card shadow="always" style="width:98%;margin:20px auto 0;">
    <el-form ref="dataForm" style="width:70%;" label-width="100px" size="small" :model="formModel" :rules="rules">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item label="头像">
            <el-upload
                class="avatar-uploader"
                :class="{disabled:uploadDisabled}"
                :action="uploadUrl"
                list-type="picture-card"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                accept=".jpg,.jpeg,.png"
                :data="type"
                :file-list="fileList"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item label="卡号" prop="strCardNumber">
            <el-input v-model="formModel.strCardNumber" />
          </el-form-item>
          <el-form-item label="姓名" prop="strName">
            <el-input v-model="formModel.strName" />
          </el-form-item>
          <el-form-item label="性别">
            <el-radio v-model="formModel.strSex" label="1">男</el-radio>
            <el-radio v-model="formModel.strSex" label="0">女</el-radio>
          </el-form-item>
          <el-form-item label="工号" prop="title">
            <el-input v-model="formModel.strStudentNumber" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item label="类型" prop="strReaderType">
            <el-select v-model="formModel.strReaderType" placeholder="请选择" @change="getLXStrId">
              <el-option v-for="item in dzlxList" :key="item.strId" :label="item.strName" :value="item.strName" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item :prop="isStudent ? 'strCollectionLocationId' : 's'" label="选择书屋">
            <el-select v-model="formModel.strCollectionLocationId" placeholder="请选择" @change="swChange">
              <el-option
                  v-for="item in swList"
                  :key="item.strId"
                  :label="item.strName"
                  :value="item.strId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item label="所属单元" :prop="isTeacher ? 'strUnitId' : 's'">
            <el-select v-model="formModel.strUnitId" class="filter-item" placeholder="请选择" @change="getBMName">
              <el-option v-for="item in bmList" :key="item.strId" :label="item.strNodeName" :value="item.strId" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="xsSize" :sm="smSize" :md="mdSize" :lg="lgSize" :xl="xlSize">
          <el-form-item :prop="isStudent ? 'strStudyPhase' : 's'" label="学段">
            <el-select v-model="formModel.strStudyPhase" placeholder="请选择">
              <el-option v-for="item in xdList" :key="item.strName" :label="item.strName" :value="item.strName" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item :prop="isStudent ? 'strGradeId' : 's'" label="级">
            <el-select v-model="formModel.strGradeId" placeholder="请选择" @change="njChange">
              <el-option v-for="item in njList" :key="item.strId" :label="item.strName" :value="item.strId" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item :prop="isStudent ? 'strClassId' : 's'" label="班">
            <el-select v-model="formModel.strClassId" placeholder="请选择" @change="bjChange">
              <el-option v-for="item in bjList" :key="item.strId" :label="item.strName" :value="item.strId" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="有效证件">
            <el-select v-model="formModel.strValidDocumentation" placeholder="请选择">
              <el-option v-for="item in cardList" :key="item.name" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="证件号码">
            <el-input v-model="formModel.strIDNumber" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="联系方式">
            <el-input v-model="formModel.strContact" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="登记日期">
            <el-date-picker
                v-model="formModel.dtRecordDate"
                type="date"
                placeholder="选择日期"
                value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="操作员">
            <el-input v-model="formModel.strOperator" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="电子邮箱">
            <el-input v-model="formModel.strEmailaddr" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item prop="title" label="备注">
            <el-input v-model="formModel.strRemark" type="textarea" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
export default {
  name:'ReaderRegister',
  data() {
    return {
      formModel: {
        strSex: '1'
      },
      bmList: [],
      swList: [],
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      cardList: [
        {
          name: '身份证'
        },
        {
          name: '其他证件'
        }
      ],
      dzlxList: [],
      uploadUrl: '',
      type: {
        strModule: 'book'
      },
      fileList: [],
      rules: {
        strCardNumber: [
          { required: true, message: '请输入卡号', trigger: 'blur' }
        ],
        strName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        strReaderType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        strUnitId: [
          { required: true, message: '请选择单元', trigger: 'change' }
        ],
        strCollectionLocationId: [
          { required: true, message: '请选择书屋', trigger: 'change' }
        ],
        strGradeId: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        strClassId: [
          { required: true, message: '请选择小组', trigger: 'change' }
        ],
        strStudyPhase: [
          { required: true, message: '请选择学段', trigger: 'change' }
        ]
      },
      njList: [],
      bjList: [],
      isStudent: false,
      isTeacher: false,
      xdList: [
        {
          strName: '其他'
        },
        {
          strName: '小学'
        },
        {
          strName: '初中'
        },
        {
          strName: '高中'
        },
        {
          strName: '大学'
        },
        {
          strName: '硕士'
        },
        {
          strName: '博士'
        }
      ]
    }
  },
  computed: {
    uploadDisabled: function() {
      if (this.fileList.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    // this.getSWList()
    // this.getDZLXList()
    // this.fetchReaderGradeList()
    // this.getBMList()
    // this.uploadUrl = process.env.VUE_APP_BASE_API + '/uploadFile/addFile'
  },
  // methods: {
  //   // 获取书屋
  //   getSWList() {
  //     fetchSWList().then((res) => {
  //       if (res.code === 0) {
  //         this.swList = res.map.list
  //       }
  //     })
  //   },
  //   // 获取单元
  //   getBMList(val) {
  //     this.bmList = []
  //     const param = {
  //       strLibraryAddress: val,
  //       pageIndex: -1
  //     }
  //     fetchDZBMList(param).then(res => {
  //       if (res.code === 0) {
  //         this.bmList = res.map.list
  //       }
  //     })
  //   },
  //   // 获取读者类型
  //   getDZLXList() {
  //     fetchDZLXList().then(res => {
  //       if (res.code === 0) {
  //         this.dzlxList = res.map.list
  //       }
  //     })
  //   },
  //   // 获取部门
  //   fetchReaderGradeList() {
  //     fetchReaderGradeList().then(res => {
  //       if (res.code === 0) {
  //         this.njList = res.map.list
  //       }
  //     })
  //   },
  //   // 根据部门id获取部门名称
  //   njChange(val) {
  //     this.njList.forEach(item => {
  //       if (item.strId === val) {
  //         this.formModel.strGrade = item.strName
  //       }
  //     })
  //     const param = {
  //       strGradeId: val
  //     }
  //     fetchReaderClassList(param).then(res => {
  //       if (res.code === 0) {
  //         this.bjList = res.map.list
  //       }
  //     })
  //   },
  //   // 根据小组id获取部门名称
  //   bjChange(val) {
  //     this.bjList.forEach(item => {
  //       if (item.strId === val) {
  //         this.formModel.strClass = item.strName
  //       }
  //     })
  //   },
  //   // 根据类型id筛选类型名称
  //   getBMName(val) {
  //     this.bmList.forEach(item => {
  //       if (item.strId === val) {
  //         this.formModel.strUnitName = item.strNodeName
  //       }
  //     })
  //   },
  //   // 根据书屋筛选单元以及筛选书屋名称
  //   swChange(val) {
  //     this.swList.forEach(item => {
  //       if (item.strId === val) {
  //         this.formModel.strCollectionLocation = item.strName
  //       }
  //     })
  //   },
  //   getLXStrId(val) {
  //     this.dzlxList.forEach(item => {
  //       if (item.strName === val) {
  //         this.formModel.strReaderTypeId = item.strId
  //       }
  //     })
  //     if (val === '学生') {
  //       this.isStudent = true
  //       this.isTeacher = false
  //     } else if (val === '教师') {
  //       this.isTeacher = true
  //       this.isStudent = false
  //     }
  //   },
  //   handleSuccess(res, file, fileList) {
  //     this.fileList = fileList
  //     this.formModel.strHeadPortraitUrl = res.map.strUrl
  //   },
  //   handleRemove(file, fileList) {
  //     this.fileList = fileList
  /*  },*/
  /*  onSubmit() {*/
  /*    this.$refs['dataForm'].validate(valid => {*/
  /*      if (valid) {*/
  /*        btnDZAdd(this.formModel).then(res => {*/
  /*          if (res.code === 0) {*/
  /*            this.$message({*/
  /*              message: '添加成功',*/
  /*              type: 'success'*/
  /*            })*/
  /*            this.$nextTick(() => {*/
  /*              this.$refs['dataForm'].clearValidate()*/
  /*            })*/
  /*            this.formModel = {*/
  /*              strSex: '1'*/
  //             }
  //             this.fileList = []
  //             this.isStudent = false
  //             this.isTeacher = false
  //           }
  //         })
  //       }
  //     })
  //   }
  // }
}
</script>
<style scoped>
.disabled /deep/ .el-upload--picture-card{
  display:none
}
</style>

