<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          size="mini"
          type="primary"
          style="margin-bottom:10px"
          icon="el-icon-plus"
          @click="handleCreate"
      >添加</el-button>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <el-table-column label="封面" align="center">
        <template v-slot="{row}">
          <img :src="localpath+'avatars/'+row" style="height: 200px">
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="100"
      >
        <template v-slot="{row}">
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog width="30%" :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          label-position="left"
          label-width="100px"
          style="margin-left:40px;"
          size="small"
      >
        <el-upload
            class="avatar-uploader"
            :class="{disabled}"
            action=""
            list-type="picture-card"
            :on-change="handleSuccess"
            :on-remove="handleRemove"
            accept=".jpg,.jpeg,.png"
            :auto-upload="false"
            :file-list="fileList"
        >
          <i class="el-icon-plus" />
        </el-upload>
      </el-form>
      <div class="dialog-footer">
        <div style="float: right">
          <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="mini" @click="add()">确定</el-button>
        </div>
        <p style="color: #ffffff">三阅</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:'Holiday',
  data() {
    return {
      list: null,
      total: 0,
      disabled:'',
      listLoading: true,
      listQuery: {
        Page: 1,
        Perpage: 10,
        OrderBy: {"DTCreatedDate":-1},
      },
      myurl:this.localpath+'books/',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      formModel: {
        StrCovers:[]
      }
    }
  },
  created() {
    this.getList()
  },
  computed: {
    // uploadDisabled: function() {
    //   if (this.fileList.length > 0) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
  methods: {
    // 获取封面列表
    getList() {
      this.$axios({
        url: this.localpath + 'organizations/images',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.listLoading = false
        }
      })
    },
    // 上传图片成功
    handleSuccess(file, fileList) {
      if (this.formModel.StrCovers.length<3){
        this.fileList = fileList
        //图片文件转base64
        this.convertImgToBase64(file.raw, base64Str => this.formModel.StrCovers.push(base64Str) , error => console.log(error));
      }else {
        this.disabled='disabled'
      }
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.disabled=''
      this.fileList = fileList
      this.convertImgToBase64(file.raw,
          base64Str => this.formModel.StrCovers.splice(this.formModel.StrCovers.findIndex(e=>e==base64Str),1) ,
      error => console.log(error));
    },
    //转base64
    convertImgToBase64(imageFile, callback, errorCallback) {
      try {
        let reader = new FileReader()
        reader.readAsDataURL(imageFile)
        reader.onload = function(e) {
          if (callback) {
            let base64Str = e.target.result
            callback(base64Str)
          }
        }
      } catch (error) {
        console.error(error)
        if (errorCallback) {
          errorCallback(error)
        }
      }
    },
    // 弹出添加界面
    handleCreate() {
      this.formModel = {
        StrCovers: []
      }
      this.fileList = []
      this.dialogFormVisible = true
      this.dialogStatus = 'create'
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    //添加图片
    add(){
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$axios({
            url: this.localpath + 'organizations/covers',
            method: 'put',
            data:JSON.stringify(this.formModel),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '图片添加成功',
                type: 'success'
              })
            }else{
              this.$message({
                message:res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
          this.dialogFormVisible = false
        }
      })
    },
    //删除
    handleDelete(row) {
      this.$confirm('确定要删除该封面吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$axios({
          url: this.localpath + 'organizations/covers/'+row,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }else{
            this.$notify({
              message:res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    }
  }
}
</script>
<style>
.disabled /deep/ .el-upload--picture-card{
  display:none
}

</style>